import React from 'react';
import { Font, Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
//import font from "./fonts/Roboto-Regular.ttf";
import fontbold from "./fonts/Roboto-Bold.ttf";
/* Font.register({
    family: "RobotoBold",
    format: "truetype",
    src: fontbold
}); */

Font.register({
    family: 'RobotoBold',
    src: fontbold,
});
const styles = StyleSheet.create({
    /* fontText: {
        fontFamily: 'RobotoBold',
        fontWeight: 'bold',
    }, */
    page: {
        paddingTop: 35,
        paddingBottom: 35,
        paddingHorizontal: 30,
        backgroundColor: '#fff'
    },
    dados: {
        marginTop: 5,
        flex: 1,
        display: "flex"
    },
    dados_2: {
        marginTop: 5
    },
    text: {
        fontSize: 12,
        marginLeft: 50
        /* fontFamily: 'RobotoBold',
        fontWeight: 'bold', */
    },
    viewsSideBySide: {
        backgroundColor: '#fff',
        flexDirection: 'row', 
        alignItems: 'stretch',
        marginTop: 15
    },


    //HEADER
    logo: {
        width:100,
    },
    header: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    logoBackground:{
        backgroundColor: "#969696",
        width: 100,
        height: 35,
        marginTop: 18
    },
    titleHeader: {
        fontSize: 12,
        textAlign: 'left',
        color: '#666666',
        marginLeft: 4
    },
    textHeader: {
        fontSize: 9,
        textAlign: 'left',
        color: '#666666',
        paddingTop: 2,
        marginLeft: 4
    },
    lineHeader: {
        borderBottomColor: '#666666',
        borderBottomWidth: 1,
        paddingTop:10,
        marginBottom: 5
    },


    //INFO
    viewsRow: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    //LEFT
    titleInfo: {
        fontSize: 14,
        color: '#666666',
    },
    textInfo1: {
        fontSize: 10,
        color: '#666666',
        paddingTop: 2,
    },
    textInfo2: {
        fontSize: 8,
        paddingTop: 2,
        color: '#383838',
    },
    lineMid: {
        borderBottomColor: '#666666',
        borderBottomWidth: 1,
        marginTop:5,
        marginBottom:5,
    },
    borderLeft: {
        borderLeftColor: '#b49594',
        borderLeftWidth: 5,
    },
    //RIGHT
    titleInfo2: {
        fontSize: 16,
        color: '#383838',
        fontWeight: 'bold',
        textAlign: 'right',
        marginRight: 245,
        paddingTop: 10,
        paddingBottom: 10,
    },
    textInfo: {
        fontSize: 11,
        color: '#383838',
        paddingTop: 2,
        textAlign: 'right',
        marginRight: 245,
    },
    titleSection: {
        fontSize: 13,
        textAlign: 'center',
        color: '#000',
        paddingTop: 15,
        paddingBottom: 10
    },

    //BODY
    table100: { 
        display: "table", 
        width: 535,
        marginTop: 10
    },
    table: { 
        display: "table", 
        width: "50%", 
        borderStyle: "solid", 
        borderTopColor: '#b49594',
        borderTopWidth: 1,
        marginTop: 10,
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableRowUnder: {
        margin: "auto", 
        flexDirection: "row",
        borderStyle: "solid", 
        borderTopColor: '#b49594',
        borderTopWidth: 1,
    },
    tableCol1: {
        width: "100%", 
        borderStyle: "solid", 
        borderTopColor: '#b49594',
        borderTopWidth: 1,
        paddingBottom: 3
    },
    tableCol2: { 
        width: "42.5%"
    }, 
    tableCol2_1: { 
        width: "90%"
    }, 
    tableCol2_2: { 
        width: "7.5%"
    },
    tableCol2_3: { 
        width: "10%"
    },
    tableCol2_4: { 
        width: "50%"
    },
    tableCol_Agreg_1: {
        width: "10%"
    },
    tableCol_Agreg_2: {
        width: "15%"
    },
    tableCol_Agreg_3: {
        width: "10%"
    },
    tableCol_Agreg_4: {
        width: "20%"
    },
    tableCol_Agreg_5: {
        width: "20%"
    },
    tableCol_Agreg_6: {
        width: "25%"
    },
    tableCol_Agressor_1: {
        width: "10%"
    },
    tableCol_Agressor_2: {
        width: "25%"
    },
    tableCol_Agressor_3: {
        width: "15%"
    },
    tableCol_Agressor_4: {
        width: "25%"
    },
    tableCol_Agressor_5: {
        width: "25%"
    },
    tableCol_Espec_1: {
        width: "40%"
    },
    tableCol_Espec_2: {
        width: "5%"
    },
    tableCol_Espec_3: {
        width: "5%"
    },
    tableCol_Espec_4: {
        width: "25%"
    },
    tableCol_Espec_5: {
        width: "25%"
    },
    tableCell: { 
        textAlign: "left",
        marginTop: 3, 
        fontSize: 8,
        paddingBottom:4,
        paddingLeft:1
    },
    tableCellSmall: {
        fontSize: 6,
        paddingLeft:1
    },
    tableCellCenter: { 
        margin: "auto", 
        marginTop: 3, 
        fontSize: 8,
        paddingBottom:4,
        paddingLeft:1
    },
    tableCellNoMargin: { 
        marginTop: 3, 
        fontSize: 8,
        /* fontFamily: 'RobotoBold',
        fontWeight: 'bold', */
    },
    colorTitleBlue: {
        backgroundColor: '#b49594',
        color: '#fff'
    },
    colorTextGreen: {
        backgroundColor: '#94BD5E',
    },
    colorTextRed: {
        backgroundColor: '#e66a6a',
    },
    colorTextGray: {
        backgroundColor: '#EBEBFF',
    },
    generalBorderBottom: {
        borderStyle: "solid", 
        borderBottomColor: '#b49594',
        borderBottomWidth: 1
    },
    footer: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    footerNumber: {
        fontSize: 9,
        textAlign: 'right',
        paddingRight: 0,
        marginBottom:-15,
    },
    footerImg: {
        paddingRight: 0,
        marginBottom:-15,
        textAlign: 'center',
        width:'250px'
    }
});
// Create Document Component
export function MyDocument(props) {
    let dados = [];
    //console.log("pdf props: ", props.data);
    dados = props.data; 
    console.log(dados);
    const info = dados[2];
    //console.log(dados[2][16]);
  
    return (
      <Document>
        <Page size="A4" style={styles.page} wrap>

            {/* HEADER */}
            <View style={styles.header}>
                <View> 
                <Image
                    style={{
                    width:'65px', marginTop: 0}}
                    src="/assets/img/logo/logo-cig.png"
                />
                </View>
                <View>
                    <Text style={[styles.titleInfo, {textAlign: "left",marginTop:"8px"}]}>CIG - Gestão de Vagas</Text>
                    <Text style={[styles.textInfo1, {paddingTop: 5, textAlign: "left"}]}>Comissão para a Cidadania e a Igualdade de Género</Text>
                    <Text style={[styles.textInfo1, {paddingTop: 5, textAlign: "left"}]}>E-mail : vagas.vd@cig.gov.pt</Text>
                </View>
                <View style={[styles.borderRight,{width:"40%",float:"right"}]}>
                    <Text style={[styles.titleInfo, {textAlign: "right",marginTop:"8px"}]}>Relatório de Encaminhamento</Text>
                    <Text style={[styles.textInfo1, {paddingTop: 5, textAlign: "right"}]}>Data de criação: {info['data']}</Text>
                    <Text style={[styles.textInfo2, {paddingTop: 5, textAlign: "right"}]}>(Artigo 15º do dec-regulamentar 2/2018, 24 de Janeiro)</Text>
                </View>
            </View>
            <View style={styles.lineHeader}></View>

            {/* INFO */}
            {/*<View style={styles.viewsRow}>
            
                <View style={styles.borderLeft}>
                    <Text style={[styles.titleInfo, {paddingLeft: 4, paddingTop:4}]}>Relatório de Encaminhamento</Text>
                    <Text style={[styles.textInfo1, {paddingTop: 10, paddingLeft: 4}]}>Data de criação: {info['data']}</Text>
                    <Text style={[styles.textInfo2, {paddingTop: 10, paddingLeft: 4}]}>(Artigo 15º do dec-reguamentar 2/2018, 24 de Janeiro)</Text>
                </View>
                
                <View style={{marginRight: 14}}>
                    <Text style={styles.titleInfo2}>Entidade Encaminhadora:</Text>
                    <Text style={styles.textInfo}>Nome: {info['nameEnc']}</Text>
                    <Text style={styles.textInfo}>Contacto: {info['phoneEnc']}</Text>
                    </View>
            </View>*/}

            {/* PDF */}
            <View style={styles.dados}>
                    
                {/*<Text style={[styles.titleInfo2, {marginBottom: 0, textAlign: "center", marginRight: 0}]}>Entidade encaminhadora</Text>*/}
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Entidade Encaminhadora</Text></View> 
                    </View> 
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol2_2, {borderTopWidth: 0}]}><Text style={[styles.tableCell, styles.fontText]}>Instituição</Text></View> 
                        <View style={[styles.tableCol2, styles.generalBorderBottom, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCell}>{info['nameEnc']}</Text></View>
                    
                        <View style={[styles.tableCol2_2, {borderTopWidth: 0}]}><Text style={[styles.tableCell, {textAlign: "right", paddingRight: 2}]}>Contacto</Text></View> 
                        <View style={[styles.tableCol2, styles.generalBorderBottom, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCell}>{info['phoneEnc']}</Text></View>
                    </View>
                </View>
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Entidade de Acolhimento</Text></View> 
                    </View> 
                    {
                        info['aco'].map(aco => (
                            <View key={aco.id}>
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCol2_2, {borderTopWidth: 0}]}><Text style={[styles.tableCell, styles.fontText]}>Instituição</Text></View> 
                                    <View style={[styles.tableCol2, styles.generalBorderBottom, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCell}>{aco.name}</Text></View>
                               
                                    <View style={[styles.tableCol2_2, {borderTopWidth: 0}]}><Text style={[styles.tableCell, {textAlign: "right", paddingRight: 2}]}>Contacto</Text></View> 
                                    <View style={[styles.tableCol2, styles.generalBorderBottom, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCell}>{aco.cont}</Text></View>
                                </View>
                            </View>
                        ))
                    }     
                </View>
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Identificação da/o utente</Text></View> 
                    </View> 
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol2_3, {borderTopWidth: 0}]}><Text style={[styles.tableCell, styles.fontText]}>Nome</Text></View> 
                        <View style={[styles.tableCol2_1, styles.generalBorderBottom, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCell}>{info['utente']['name']}</Text></View>
                    </View>
                    <View style={styles.tableRow}>  
                        <View style={styles.tableCol2_3}><Text style={styles.tableCell}>Morada</Text></View> 
                        <View style={[styles.tableCol2_1, styles.generalBorderBottom, styles.colorTextGray]}><Text style={styles.tableCell}>{info['utente']['address']}</Text></View>
                    </View>
                    <View style={styles.tableRow}>  
                        <View style={styles.tableCol2_3}><Text style={styles.tableCell}>Contacto</Text></View> 
                        <View style={[styles.tableCol2_1, styles.generalBorderBottom, styles.colorTextGray]}><Text style={styles.tableCell}>{info['utente']['phone']}</Text></View>
                    </View>
                    <View style={styles.tableRow}>  
                        <View style={styles.tableCol2_3}><Text style={styles.tableCell}>Data Nascimento</Text></View> 
                        <View style={[styles.tableCol2_1, styles.generalBorderBottom, styles.colorTextGray]}><Text style={styles.tableCell}>{info['utente']['birthday']}</Text></View>
                    </View>
                </View>
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Composição Agregado Familiar que acompanha a/o utente</Text></View> 
                    </View> 
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol_Agreg_1, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Elemento</Text></View>
                        <View style={[styles.tableCol_Agreg_2, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Nome</Text></View>
                        <View style={[styles.tableCol_Agreg_3, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>D.N.</Text></View>
                        <View style={[styles.tableCol_Agreg_4, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Parentesco</Text></View>
                        <View style={[styles.tableCol_Agreg_5, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Profissão/Ocupação</Text></View>
                        <View style={[styles.tableCol_Agreg_6, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Estabelecimento Ensino/Ano</Text></View>
                    </View>
                    {
                        info['agregado'].map((agregado,index) => (
                            <View key={agregado.id} style={[styles.tableRow, styles.generalBorderBottom]}>
                                <View style={styles.tableCol_Agreg_1}><Text style={styles.tableCellCenter}>{(parseInt(index)+1)}</Text></View> 
                                <View style={styles.tableCol_Agreg_2}><Text style={styles.tableCellCenter}>{agregado.name}</Text></View> 
                                <View style={styles.tableCol_Agreg_3}><Text style={styles.tableCellCenter}>{agregado.dt}</Text></View> 
                                <View style={styles.tableCol_Agreg_4}><Text style={styles.tableCellCenter}>{agregado.rel}</Text></View> 
                                <View style={styles.tableCol_Agreg_5}><Text style={styles.tableCellCenter}>{agregado.prof}</Text></View> 
                                <View style={styles.tableCol_Agreg_6}><Text style={styles.tableCellCenter}>{agregado.esc}</Text></View> 
                            </View>
                        ))
                    }     
                </View>
               
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Identificação da pessoa que praticou o ato violento</Text></View> 
                    </View> 
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol_Agressor_1, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Elemento</Text></View>
                        <View style={[styles.tableCol_Agressor_2, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Nome</Text></View>
                        <View style={[styles.tableCol_Agressor_3, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>D.N.</Text></View>
                        <View style={[styles.tableCol_Agressor_4, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Parentesco</Text></View>
                        <View style={[styles.tableCol_Agressor_5, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Profissão/Ocupação</Text></View>
                    </View>
                    {
                        info['agressor'].map((agressor,index) => (
                            <View key={agressor.id} style={[styles.tableRow, styles.generalBorderBottom]}>
                                <View style={styles.tableCol_Agressor_1}><Text style={styles.tableCellCenter}>{(parseInt(index)+1)}</Text></View> 
                                <View style={styles.tableCol_Agressor_2}><Text style={styles.tableCellCenter}>{agressor.name}</Text></View> 
                                <View style={styles.tableCol_Agressor_3}><Text style={styles.tableCellCenter}>{agressor.dt}</Text></View> 
                                <View style={styles.tableCol_Agressor_4}><Text style={styles.tableCellCenter}>{agressor.rel}</Text></View> 
                                <View style={styles.tableCol_Agressor_5}><Text style={styles.tableCellCenter}>{agressor.prof}</Text></View> 
                            </View>
                        ))
                    }     
                </View>
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Informações Adicionais (relativas ao agregado a acolher)</Text></View> 
                    </View> 
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol_Espec_1, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                        <View style={[styles.tableCol_Espec_2, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Sim</Text></View>
                        <View style={[styles.tableCol_Espec_3, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Não</Text></View>
                        <View style={[styles.tableCol_Espec_4, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Elemento</Text></View>
                        <View style={[styles.tableCol_Espec_5, styles.colorTextGray, {borderTopWidth: 0}]}><Text style={styles.tableCellCenter}>Especifique</Text></View>
                    </View>
                    <View style={[styles.tableRow, styles.generalBorderBottom]}>
                        <View style={styles.tableCol_Espec_1}><Text style={styles.tableCell}>Necessidades Específicas de Saúde</Text></View> 
                        <View style={styles.tableCol_Espec_2}><Text style={styles.tableCellCenter}>{info['espec'][0]['simnao'] === "Sim" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_3}><Text style={styles.tableCellCenter}>{info['espec'][0]['simnao'] === "Não" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_4}><Text style={styles.tableCellCenter}>{info['espec'][0]['simnao'] === "Sim" ? info['espec'][0]['ele'] : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_5}><Text style={styles.tableCellCenter}>{info['espec'][0]['simnao'] === "Sim" ? info['espec'][0]['esp'] : '' }</Text></View> 
                    </View>  
                    <View style={[styles.tableRow, styles.generalBorderBottom]}>
                        <View style={styles.tableCol_Espec_1}><Text style={styles.tableCell}>Necessidades Educativas Especiais</Text></View> 
                        <View style={styles.tableCol_Espec_2}><Text style={styles.tableCellCenter}>{info['espec'][1]['simnao'] === "Sim" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_3}><Text style={styles.tableCellCenter}>{info['espec'][1]['simnao'] === "Não" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_4}><Text style={styles.tableCellCenter}>{info['espec'][1]['simnao'] === "Sim" ? info['espec'][1]['ele'] : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_5}><Text style={styles.tableCellCenter}>{info['espec'][1]['simnao'] === "Sim" ? info['espec'][1]['esp'] : '' }</Text></View> 
                    </View>  
                    <View style={[styles.tableRow, styles.generalBorderBottom]}>
                        <View style={styles.tableCol_Espec_1}><Text style={styles.tableCell}>Frequência Escolar (menores)</Text></View> 
                        <View style={styles.tableCol_Espec_2}><Text style={styles.tableCellCenter}>{info['espec'][2]['simnao'] === "Sim" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_3}><Text style={styles.tableCellCenter}>{info['espec'][2]['simnao'] === "Não" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_4}><Text style={styles.tableCellCenter}>{info['espec'][2]['simnao'] === "Sim" ? info['espec'][2]['ele'] : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_5}><Text style={styles.tableCellCenter}>{info['espec'][2]['simnao'] === "Sim" ? info['espec'][2]['esp'] : '' }</Text></View> 
                    </View>  
                    <View style={[styles.tableRow, styles.generalBorderBottom]}>
                        <View style={styles.tableCol_Espec_1}><Text style={styles.tableCell}>CPCJ</Text></View> 
                        <View style={styles.tableCol_Espec_2}><Text style={styles.tableCellCenter}>{info['espec'][3]['simnao'] === "Sim" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_3}><Text style={styles.tableCellCenter}>{info['espec'][3]['simnao'] === "Não" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_4}><Text style={styles.tableCellCenter}>{info['espec'][3]['simnao'] === "Sim" ? info['espec'][3]['ele'] : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_5}><Text style={styles.tableCellCenter}>{info['espec'][3]['simnao'] === "Sim" ? info['espec'][3]['esp'] : '' }</Text></View> 
                    </View>  
                    <View style={[styles.tableRow, styles.generalBorderBottom]}>
                        <View style={styles.tableCol_Espec_1}><Text style={styles.tableCell}>Denúncia pelo Crime de VD</Text></View> 
                        <View style={styles.tableCol_Espec_2}><Text style={styles.tableCellCenter}>{info['espec'][5]['simnao'] === "Sim" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_3}><Text style={styles.tableCellCenter}>{info['espec'][5]['simnao'] === "Não" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_4}><Text style={styles.tableCellCenter}>{info['espec'][5]['simnao'] === "Sim" ? info['espec'][5]['ele'] : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_5}><Text style={styles.tableCellCenter}>{info['espec'][5]['simnao'] === "Sim" ? info['espec'][5]['esp'] : '' }</Text></View> 
                    </View>
                    <View style={[styles.tableRow, styles.generalBorderBottom]}>
                        <View style={styles.tableCol_Espec_1}><Text style={styles.tableCell}>Outros processos</Text><Text style={styles.tableCellSmall}>(crime, cível, Promoção e Protecção, Tutelar educativo, etc)</Text></View> 
                        <View style={styles.tableCol_Espec_2}><Text style={styles.tableCellCenter}>{info['espec'][4]['simnao'] === "Sim" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_3}><Text style={styles.tableCellCenter}>{info['espec'][4]['simnao'] === "Não" ? 'X' : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_4}><Text style={styles.tableCellCenter}>{info['espec'][4]['simnao'] === "Sim" ? info['espec'][4]['ele'] : '' }</Text></View> 
                        <View style={styles.tableCol_Espec_5}><Text style={styles.tableCellCenter}>{info['espec'][4]['simnao'] === "Sim" ? info['espec'][4]['esp'] : '' }</Text></View> 
                    </View>    
                </View>
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Diagnóstico Sumário</Text></View> 
                    </View> 
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol2, styles.generalBorderBottom, {borderTopWidth: 0,width:"100%"}]}><Text style={[styles.tableCell, styles.fontText]}>{info['sumario']}</Text></View>
                    </View>
                </View>
                <View style={[styles.table100, {marginRight: 16}]}>
                    <View style={[styles.tableRow, styles.colorTitleBlue]}>  
                        <View style={styles.tableCol1}><Text style={styles.tableCellCenter}>Fundamentação do Pedido</Text></View> 
                    </View> 
                    <View style={styles.tableRow}>
                        <View style={{borderTopWidth: 0,width:"5%"}}><Text style={[styles.tableCell, styles.fontText]}>Data</Text></View> 
                        <View style={{borderTopWidth: 0,width:"95%"}}><Text style={styles.tableCell}>{info['data']}</Text></View>
                    </View>
                    <View style={[styles.tableRow,{marginTop:5}]}>
                        <View style={{borderTopWidth: 0,width:"100%"}}><Text style={[styles.tableCellCenter,{fontSize:9,fontWeight:"bold"}]}>A entidade encaminhadora</Text><Text style={[styles.tableCellCenter,{fontSize:8,fontWeight:"bold"}]}>{info['nameEnc']}</Text></View>
                    </View>
                </View>
            </View>    

            {/* FOOTER */}        
            <View render={({ pageNumber, totalPages }) => (
                <View style={styles.footer}>
                    {/*<Text style={[styles.footerDescription, styles.borderLeft]}>
                        INFORMAÇÃO: Documento processado por computador. Válido sem o reconhecimento de assinatura e carimbo.{"\n"}
                        Todos os direitos reservados - EMBRAER Portugal, Estrutura em Compósitos S.A.
            </Text>*/}
                    <Image
                        style={styles.footerImg}
                        src="/assets/img/logo/logo_comb.jpg"
                    />
                    <Text style={styles.footerNumber} render={({ pageNumber, totalPages }) => (
                        `Página ${pageNumber} de ${totalPages}`
                    )} fixed /> 
                </View>
            )} fixed />
            
        </Page>        
      </Document>
    )
  
};

