import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import Chart from 'react-apexcharts';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import api from '../../services/api';
import translations from '../translations.json';
import checkSession from '../../utils/checkSession';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';

export default function Dashboard() {
	var session = checkSession();
	const dashop = 2;
	if (!session.permissions[0]['permission_type'][0]['permission'][0].hasPermission) {
		if (session.permissions[2]['permission_type'][0]['permission'][0].hasPermission) {
			// window.location.href = '/insts';
		}else{
			if (session.permissions[3]['permission_type'][0]['permission'][0].hasPermission) {
				// window.location.href = '/entity';
			}else{
				if (session.permissions[1]['permission_type'][0]['permission'][0].hasPermission) {
					// window.location.href = '/backoffice/users';
				}else{
					
					if (session.permissions[1]['permission_type'][1]['permission'][0].hasPermission) {
						// window.location.href = '/backoffice/user-types';
					}else{
						localStorage.removeItem('token');
						// window.location.href = '/';
					}
					
				}
			}
		}
	}

	const [modalDialog, setModalDialog] = useState(false);

	const [sweetAlertSuccess, setSweetAlertSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const [sweetAlertWarning, setSweetAlertWarning] = useState(false);
	const [sweetAlertWarningSuc, setSweetAlertWarningSuc] = useState(false);

	const [sweetAlertError, setSweetAlertError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const [currentVacancyA, setCurrentVacancyA] = useState(0);
	const [currentVacancyE, setCurrentVacancyE] = useState(0);
	const [monthRequests, setMonthRequests] = useState(0);
	const [monthRequests_ca, setMonthRequests_ca] = useState(0);
	const [monthRequests_ce, setMonthRequests_ce] = useState(0);
	const [monthRequests_vr, setMonthRequests_vr] = useState(0);
	const [monthRequests2, setMonthRequests2] = useState(0);
	const [insts, setInsts] = useState([]);
	const [valueInst, setValueInst] = useState(0);

	const [reload, setReload] = useState(false);
	const [isAco, setIsAco] = useState(false);
	const [barChart, setBarChart] = useState({
		options: {
			chart: {
				height: 350,
				type: 'bar',
			},
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: 'top',
					},
				}  
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				style: {
					fontSize: '12px',
					colors: ['#ffffff']
				}
			},
			colors: ['#0099ff', '#ff0000'],
			stroke: {
				show: true,
				width: 1,
				colors: ['#ffffff']
			},
			title: {
				text: translations[session.language]['dashboard']['charts']['district-vacancy'].title,
				align: 'center'
			},
			grid: {
				row: {
					colors: ['rgba(182, 194, 201, 0.1)', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5
				},
			},
			xaxis: {
				categories: [],
				axisBorder: {
					show: true,
					color: 'rgba(182, 194, 201, 0.5)',
					height: 1,
					width: '100%',
					offsetX: 0,
					offsetY: -1
				},
				axisTicks: {
					show: true,
					borderType: 'solid',
					color: '#b6c2c9',
					height: 6,
					offsetX: 0,
					offsetY: 0
				}
			},
			// yaxis: {
			// 	labels: {
			// 		formatter: function(val, index) {
			// 			return val.toFixed(0);
			// 		}
			// 	}
			// },
			legend: {
				show: true,
				position: 'top',
				offsetY: -10,
				horizontalAlign: 'right',
				floating: true,
			}
		},
		series: []
	});
	const [barChartReqFor, setBarChartReqFor] = useState({
		options: {
			chart: {
				height: 350,
				type: 'bar',
			},
			plotOptions: {
				bar: {
					horizontal: true,
					dataLabels: {
						position: 'top',
					},
					barHeight: '110%',
				}  
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				style: {
					fontSize: '12px',
					colors: ['#ffffff']
				}
			},
			colors: ['#32d15d', '#ff9900'],
			stroke: {
				show: true,
				width: 1,
				colors: ['#ffffff']
			},
			title: {
				text: translations[session.language]['dashboard']['charts']['requests-forwardings'].title,
				align: 'center'
			},
			grid: {
				row: {
					colors: ['rgba(182, 194, 201, 0.1)', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5,
					
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				onDatasetHover: {
					highlightDataSeries: false,
				},
			  },
			xaxis: {
				categories: [],
				axisBorder: {
					show: true,
					color: 'rgba(182, 194, 201, 0.5)',
					height: 1,
					width: '100%',
					offsetX: 0,
					offsetY: -1
				},
				axisTicks: {
					show: true,
					borderType: 'solid',
					color: '#b6c2c9',
					height: 6,
					offsetX: 0,
					offsetY: 0
				},
				
			},
			yaxis: {
				labels: {
				   maxWidth: 400
				}
			},
			legend: {
				show: true,
				position: 'top',
				offsetY: -10,
				horizontalAlign: 'right',
				floating: true,
			},
			
		},
		series: []
	});
	const [pieChart, setPieChart] = useState({
		options: {
			chart: {
				height: 350,
				type: 'pie',
			},
			dataLabels: {
				dropShadow: {
					enabled: false,
					top: 1,
					left: 1,
					blur: 1,
					opacity: 0.45
				}
			},
			
			labels: [],
			title: {
				text: translations[session.language]['dashboard']['charts']['district-institutions'].title
			}
		},
		series: []
	});

	const pickerOptions = {
		format: "DD/MM/YYYY",
		applyLabel: 'Aplicar',
		cancelLabel: 'Cancelar',
		daysOfWeek: [
			"dom",
			"seg",
			"ter",
			"qua",
			"qui",
			"sex",
			"sáb"
		],
		monthNames: [
			"janeiro",
			"fevereiro",
			"março",
			"abril",
			"maio",
			"junho",
			"julho",
			"agosto",
			"setembro",
			"outubro",
			"novembro",
			"dezembro"
		],
	}

	const today = new Date();
	const firstDayWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1).toLocaleDateString();
	const lastDayWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7).toLocaleDateString();
	const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1).toLocaleDateString();
	const lastDayMonth = new Date(today.getFullYear(), today.getMonth()+1, 0).toLocaleDateString();
	const [dateRange, setDateRange] = useState({ startDate: firstDayMonth, endDate: lastDayMonth });

	useEffect(() => {
		loadData({ startDate: firstDayMonth, endDate: lastDayMonth });
	}, []);

	async function loadData(dates) {
		setReload(true);
		// console.log('loadData', dates)
		try {
			await api.post('/dashboard', dates)
			.then(response => {
				setCurrentVacancyA(response.data.currentVacancyA);
				setCurrentVacancyE(response.data.currentVacancyE);
				setMonthRequests(response.data.monthRequests);
				setMonthRequests_ca(response.data.monthRequests_ca);
				setMonthRequests_ce(response.data.monthRequests_ce);
				setMonthRequests_vr(response.data.monthRequests_vr);
				setMonthRequests2(response.data.monthRequests2);
				setInsts(response.data.insts);
				setBarChart(prevState => ({
					options: {
						...prevState.options,
						xaxis: {
							...prevState.options.xaxis,
							categories: response.data.barChart.categories
						}
					},
					series: response.data.barChart.series
				}));
				setBarChartReqFor(prevState => ({
					options: {
						...prevState.options,
						xaxis: {
							...prevState.options.xaxis,
							categories: response.data.barChartReqFor.categories
						}
					},
					series: response.data.barChartReqFor.series
				}));
				setPieChart(prevState => ({
					options: {
						...prevState.options,
						labels: response.data.pieChart.labels
					},
					series: response.data.pieChart.series
				}));
				setReload(false);
			});
		} catch (err) {
			alert(err.message);
		}
	}
	function handleFilterDateRange(event, picker) {
		
		const dates = {
			startDate: picker.startDate._d.toLocaleDateString(),
			endDate: picker.endDate._d.toLocaleDateString()
		}
		
		setDateRange(dates);
		loadData(dates);
	}

	async function generateExcel() {
		try {
			await api.post('/dashboard-excel', dateRange, {
				responseType: "blob",
			})
			.then(response => {
				if(response.status === 200) {
					let dateRangeStartText = dateRange.startDate.split('/').reverse().toString().replaceAll(',', '')
					let dateRangeEndText = dateRange.endDate.split('/').reverse().toString().replaceAll(',', '')
					let data = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
					let csvURL = window.URL.createObjectURL(data);
					let tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', `data_${dateRangeStartText}_${dateRangeEndText}.xlsx`);
					tempLink.click();
				}
			});
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}

	function clearFilter() {
		setDateRange({ startDate: firstDayMonth, endDate: lastDayMonth });
		loadData({ startDate: firstDayMonth, endDate: lastDayMonth });
	}
	async function toggleModal(idmodal) {
		await setModalDialog(!modalDialog);
		if(idmodal !== 0){
			const inst = insts.find(x => x.id === idmodal);
			inst.ident === 3 ? setIsAco(true) : setIsAco(false);
			document.getElementById('nameInst').innerHTML = inst.name;
			document.getElementById('emailInst').innerHTML = inst.email;
			document.getElementById('addressInst').innerHTML = inst.address;
			document.getElementById('postalcodeInst').innerHTML = inst.postalcode;
			document.getElementById('cityInst').innerHTML = inst.city;
			document.getElementById('districtInst').innerHTML = inst.district;
			document.getElementById('phoneInst').innerHTML = inst.phone;
			document.getElementById('tecInst').innerHTML = inst.tec;
			if(inst.ident === 3){
				document.getElementById('coordInst').innerHTML = inst.latitude+ ", "+inst.longitude;
				document.getElementById('capacity_aInst').innerHTML = inst.capacity_a;
				document.getElementById('capacity_eInst').innerHTML = inst.capacity_e;
			}
		}
	}
	async function handleAcceptInst(id) {
		setDateRange({ startDate: firstDayMonth, endDate: lastDayMonth });
		try {
			await api.put(`/inst-dashboard/${id}`)
			.then(response => {
				setSuccessMessage(translations[session.language]['dashboard']['responses']['accept'][response.data]);
				setSweetAlertSuccess(true);
				loadData({ startDate: firstDayMonth, endDate: lastDayMonth });
			})
			.catch(err => {
				setErrorMessage(translations[session.language]['dashboard']['responses']['accept'][err.response.data]);
				setSweetAlertError(true);
			})
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}
	async function handleDeleteInst(id) {
		setDateRange({ startDate: firstDayMonth, endDate: lastDayMonth });
		try {
			await api.delete(`/inst/${id}`)
			.then(response => {
				setSuccessMessage(translations[session.language]['dashboard']['responses']['refuse'][response.data]);
				setSweetAlertSuccess(true);
				loadData({ startDate: firstDayMonth, endDate: lastDayMonth });
			})
			.catch(err => {
				setErrorMessage(translations[session.language]['dashboard']['responses']['refuse'][err.response.data]);
				setSweetAlertError(true);
			})
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	} 
	
	const defaultSorted = [
		{
			id: "name",
			desc: false
		}
	];
	const tableColumns = [
		{
			Header: translations[session.language]['dashboard']['panel']['body']['table'].name,
			accessor: "name"
		},
		{
			Header: translations[session.language]['dashboard']['panel']['body']['table'].district,
			accessor: "district"
		},
		{
			Header: translations[session.language]['dashboard']['panel']['body']['table'].type,
			accessor: "tipo"
		}
		,
		{
			id: 'button',
			Header: translations[session.language]['dashboard']['panel']['body']['table'].options,
			accessor: 'extra',
			width: 200,
			Cell: ({value}) => (
				<center>
					<button className='btn btn-info btn-sm ml-1' onClick={() => toggleModal(value.id)} title={translations[session.language]['dashboard']['panel']['body']['table']['buttons'].see}><i className="fa fa-search"></i></button>
					<button className='btn btn-success btn-sm ml-1' onClick={() => {setValueInst(value.id);setSweetAlertWarningSuc(true)}} title={translations[session.language]['dashboard']['panel']['body']['table']['buttons'].accept}><i className="fa fa-check"></i></button>
					<button className='btn btn-danger btn-sm ml-1' onClick={() => {setValueInst(value.id);setSweetAlertWarning(true)}} title={translations[session.language]['dashboard']['panel']['body']['table']['buttons'].refuse}><i className="fa fa-times"></i></button>
				</center>
			)
		}
	];
	return (
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
				<li className="breadcrumb-item active">Dashboard</li>
			</ol>
			<h1 className="page-header">{translations[session.language]['dashboard']['title']} <small>{translations[session.language]['dashboard']['title-small']}</small></h1>
			<div className="row">
				<div className="col-xl-12 col-md-12">
					<div className="float-right m-b-20 m-r-20">
						<button className='btn btn-default btn-xm mr-2' onClick={() => generateExcel()}><i className="fa fa-file-excel"></i>&nbsp;Excel Export</button>
						<DateRangePicker
							locale={pickerOptions}
							onApply={handleFilterDateRange}
						>
							<button className='btn btn-default btn-xm' ><i className="fa fa-calendar"></i>&nbsp;{dateRange.startDate === "" ? translations[session.language]['dashboard']['search']['date-range'] : `${dateRange.startDate} - ${dateRange.endDate}`}</button>
						</DateRangePicker>
						{(dateRange.startDate !== firstDayMonth || dateRange.endDate !== lastDayMonth) && (
							<button className='btn btn-default btn-xm' onClick={clearFilter}><i className="fa fa-times"></i></button>
						)}
					</div>
				</div>
			</div>
			{dashop === 1 ?
				<div className="row">
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-building fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">{translations[session.language]['dashboard']['widgets']['month-requests2'].title}</div>
								<div className="stats-number">{monthRequests2}</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: '100%'}}></div>
								</div>
								<div className="stats-desc">{translations[session.language]['dashboard']['widgets']['month-requests2'].description}</div>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-th fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">{translations[session.language]['dashboard']['widgets']['current-vacancy-a'].title}</div>
								<div className="stats-number">{currentVacancyA} %</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: currentVacancyA+'%'}}></div>
								</div>
								<div className="stats-desc">{translations[session.language]['dashboard']['widgets']['current-vacancy-a'].description}</div>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-ambulance fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">{translations[session.language]['dashboard']['widgets']['current-vacancy-e'].title}</div>
								<div className="stats-number">{currentVacancyE} %</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: currentVacancyE+'%'}}></div>
								</div>
								<div className="stats-desc">{translations[session.language]['dashboard']['widgets']['current-vacancy-e'].description}</div>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-edit fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">{translations[session.language]['dashboard']['widgets']['month-requests'].title}</div>
								<div className="stats-number">{monthRequests}</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: '100%'}}></div>
								</div>
								<div className="stats-desc">{translations[session.language]['dashboard']['widgets']['month-requests'].description}</div>
							</div>
						</div>
					</div>
					
				</div>
				:
				<div className="row">
					<div className="col-xl-4 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-building fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">{translations[session.language]['dashboard']['widgets']['month-requests2'].title}</div>
								<div className="stats-number">{monthRequests2}</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: '100%'}}></div>
								</div>
								<div className="stats-desc">{translations[session.language]['dashboard']['widgets']['month-requests2'].description}</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-house-user fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">{translations[session.language]['dashboard']['widgets']['current-vacancy-a'].title}</div>
								<div className="stats-number">{currentVacancyA} %</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: currentVacancyA+'%'}}></div>
								</div>
								<div className="stats-desc">{translations[session.language]['dashboard']['widgets']['current-vacancy-a'].description}</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-clinic-medical fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">{translations[session.language]['dashboard']['widgets']['current-vacancy-e'].title}</div>
								<div className="stats-number">{currentVacancyE} %</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: currentVacancyE+'%'}}></div>
								</div>
								<div className="stats-desc">{translations[session.language]['dashboard']['widgets']['current-vacancy-e'].description}</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-users fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">Vagas Solicitadas</div>
								<div className="stats-number">{monthRequests_vr}</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: '100%'}}></div>
								</div>
								<div className="stats-desc">Nº de Vagas Solicitadas através de Pedidos</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-th fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">Pedidos Encaminhamento Abrigo</div>
								<div className="stats-number">{monthRequests_ca}</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: '100%'}}></div>
								</div>
								<div className="stats-desc">Nº de Pedidos de Encaminhamento Abrigo</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						<div className="widget widget-stats" style={{
							margin: '0px 15px 20px 0px',
							backgroundColor: '#b49594'
						}}>
							<div className="stats-icon stats-icon-lg"><i className="fa fa-ambulance fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">Pedidos Encaminhamento Emergência</div>
								<div className="stats-number">{monthRequests_ce}</div>
								<div className="stats-progress progress">
									<div className="progress-bar" style={{width: '100%'}}></div>
								</div>
								<div className="stats-desc">Nº de Pedidos de Encaminhamento Emergência</div>
							</div>
						</div>
					</div>
					
				</div>
			}
			
			<div className="row">
				
				<div className="col-md-6">
					<div className='panel panel-inverse' style={{minHeight: '404.63px'}}>
						<div className='panel-body'>
							<div className="row">
								<div className="col-md-12"><p style={{fontWeight: "bold",fontSize: "14px"}}>{translations[session.language]['dashboard']['panel']['body'].title}</p>
								<ReactTable data={insts} columns={tableColumns} defaultPageSize={6} defaultSorted={defaultSorted} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
								{(reload && 
									<div className="panel-loader">
										<span className="spinner-small"></span>
									</div>
								)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className='panel panel-inverse' style={{minHeight: '404.63px'}}>
						<div className='panel-body'>
							<div className="row">
								<div className="col-md-12">
									<Chart type="pie" options={pieChart.options} series={pieChart.series} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<div className='panel panel-inverse'>
						<div className='panel-body'>
							<div className="row">
								<div className="col-md-12">
									<Chart type="bar" options={barChart.options} series={barChart.series} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<div className='panel panel-inverse'>
						<div className='panel-body'>
							<div className="row">
								<div className="col-md-12">
									<Chart type="bar" options={barChartReqFor.options} series={barChartReqFor.series} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal isOpen={modalDialog} toggle={() => toggleModal(0)} >
				<ModalHeader toggle={() => toggleModal(0)}>
					{translations[session.language]['entity']['modal']['header'].see}
				</ModalHeader>
				<form>
					<ModalBody>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Nome</label>
							<div className="col-md-9">
								<p type="text" id="nameInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Técnico/a</label>
							<div className="col-md-9">
								<p type="text" id="tecInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Email</label>
							<div className="col-md-9">
								<p type="text" id="emailInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Contato</label>
							<div className="col-md-9">
								<p type="text" id="phoneInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Morada</label>
							<div className="col-md-9">
								<p type="text" id="addressInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Código Postal</label>
							<div className="col-md-9">
								<p type="text" id="postalcodeInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Cidade</label>
							<div className="col-md-9">
								<p type="text" id="cityInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Distrito</label>
							<div className="col-md-9">
								<p type="text" id="districtInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15" style={(isAco) ? { display: 'flex' } : { display: 'none' }}>
							<label className="col-form-label col-md-3">Coordenadas</label>
							<div className="col-md-9">
								<p type="text" id="coordInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15" style={(isAco) ? { display: 'flex' } : { display: 'none' }}>
							<label className="col-form-label col-md-3">Capacidade CA</label>
							<div className="col-md-9">
								<p type="text" id="capacity_aInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15" style={(isAco) ? { display: 'flex' } : { display: 'none' }}>
							<label className="col-form-label col-md-3">Capacidade CE</label>
							<div className="col-md-9">
								<p type="text" id="capacity_eInst" className="form-control" />
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations[session.language]['entity']['modal']['footer'].close}</button>
					</ModalFooter>
				</form>
			</Modal>
			{(sweetAlertSuccess &&
				<SweetAlert success
					confirmBtnText="OK"
					confirmBtnBsStyle="success"
					title={translations[session.language]['alerts']['success'].title}
					onConfirm={() => setSweetAlertSuccess(false)}
				>
					{successMessage}
				</SweetAlert>
			)}
			{(sweetAlertWarning &&
				<SweetAlert warning showCancel
					confirmBtnText="Reprovar"
					cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title={translations[session.language]['alerts']['warning'].title}
					onConfirm={() => {handleDeleteInst(valueInst);setSweetAlertWarning(false)}}
					onCancel={() => setSweetAlertWarning(false)}
				>
					Deseja reprovar este pedido de acesso de Instituição?
				</SweetAlert>
			)}
			{(sweetAlertWarningSuc &&
				<SweetAlert warning showCancel
					confirmBtnText="Aprovar"
					cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="default"
					title={translations[session.language]['alerts']['warning'].title}
					onConfirm={() => {handleAcceptInst(valueInst);setSweetAlertWarningSuc(false)}}
					onCancel={() => setSweetAlertWarningSuc(false)}
				>
					Deseja aprovar este pedido de acesso de Instituição?
				</SweetAlert>
			)}
			{(sweetAlertError &&
					<SweetAlert danger
							confirmBtnText="OK"
							confirmBtnBsStyle="default"
							title={translations[session.language]['alerts']['error'].title}
							onConfirm={() => setSweetAlertError(false)}
					>
						{errorMessage}
					</SweetAlert>
			)}
		</div>
	)
}