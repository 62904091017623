import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import 'jwt-decode';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import api from '../../services/api';
import translations from '../translations.json';
import checkSession from '../../utils/checkSession';
import validateEmail from '../../utils/validateEmail';
//import validateImage from '../../utils/validateImage';
//import logout from '../../utils/logout';
//import md5 from 'md5';

export default function Insts() {
	var session = checkSession();
	const type = session.type;
	if(session.state === 3){
		window.location.href = '/profile';
	}else{
		if (!session.permissions[2]['permission_type'][0]['permission'][0].hasPermission) {
			window.location.href = '/dashboard';
		}
	}
	const [modalDialog, setModalDialog] = useState(false);

	const [sweetAlertSuccess, setSweetAlertSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const [sweetAlertWarning, setSweetAlertWarning] = useState(false);
	const [sweetAlertWarningStart, setSweetAlertWarningStart] = useState(false);
	const [sweetAlertWarningVacancy, setSweetAlertWarningVacancy] = useState(false);
	const [sweetAlertError, setSweetAlertError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const [modalEsc, setModalEsc] = useState(0);
	const [insts, setInsts] = useState([]);
	const [instsFiltered, setInstsFiltered] = useState([]);
	const [instDistrict, setInstDistrict] = useState([]);
	const [instCity, setInstCity] = useState([]);
	const [instOrig, setInstOrig] = useState([]);
	const [currentInstCity, setCurrentInstCity] = useState([]);
	const [users, setUsers] = useState([]);
	const [vacancies, setVacancies] = useState([]);
	const [requests, setRequests] = useState([]);
	const [forwardings, setForwardings] = useState([]);
	const [isMain, setIsMain] = useState(true);
	const [hasEntity, setHasEntity] = useState(0);

	const [id, setId] = useState(0);
	const [idvacancy, setIdVacancy] = useState(0);

	const [capacityA, setCapacityA] = useState(0);
	const [capacityE, setCapacityE] = useState(0);

	async function toggleModal(idmodal,esc) {
		setModalEsc(parseInt(esc));
		await setModalDialog(!modalDialog);
		if (idmodal !== 0) {
			if(esc === 1){
				const inst = insts.find(x => x.id === idmodal);
				setHasEntity(inst.ident);
				const idEntidade =  document.getElementById('idEntidade');
				if (typeof(idEntidade) != 'undefined' && idEntidade != null){
					document.getElementById('idEntidade').value = inst.ident;
				}
				const el = document.getElementById('idDistrict');
				el.value=inst.iddistrict;
				let cities = [];
				cities[0] = {id: 0, name: translations[session.language]['insts']['modal']['body']['city'].placeholder};
				instCity.map(city => parseInt(city.iddistrict) === parseInt(inst.iddistrict) && (
					cities[cities.length] = city
				)
				);
				setCurrentInstCity(cities);
				setTimeout(() => {document.getElementById('idCity').value = inst.idcity; } ,500);
				
				document.getElementById('name').value = inst.name;
				document.getElementById('email').value = inst.email;
				document.getElementById('address').value = inst.address;
				document.getElementById('postalcode').value = inst.postalcode;
				document.getElementById('phone').value = inst.phone;
				document.getElementById('capacity_a').value = inst.capacity_a;
				document.getElementById('capacity_e').value = inst.capacity_e;
				if(inst.showinfo === 1){
					document.getElementById('showInfo').checked = true;
				}
				document.getElementById('latitude').value = inst.latitude;
				document.getElementById('longitude').value = inst.longitude;
				setId(idmodal);
			}else if(esc === 2){
				const vacancie = vacancies.find(x => x.id === idmodal);
				const dt = vacancie.dt.split("T");
				document.getElementById('dt').value = dt[0];
				setCapacityA(vacancie.capacity_a);
				setCapacityE(vacancie.capacity_e);
				if(vacancie.capacity_a !== 0){
					document.getElementById('vacancy_a').value = vacancie.vacancy_a;
					document.getElementById('capacity_a').value = vacancie.capacity_a;
				}
				if(vacancie.capacity_e !== 0){
					document.getElementById('vacancy_e').value = vacancie.vacancy_e;
					document.getElementById('capacity_e').value = vacancie.capacity_e;
				}
				setIdVacancy(idmodal);
			}
		}else{
			if(esc === 1){
				setId(0);
			}else if(esc === 2){
				let inst = [];
				if(type === 3){
					inst = insts.find(x => x.id === id);
				}else{
					inst = insts.find(x => x.id === id);
				}
				if(inst){
					document.getElementById('dt').value = new Date().toISOString().split('T')[0];
					setCapacityA(inst.capacity_a);
					setCapacityE(inst.capacity_e);
					if(inst.capacity_a !== 0){
						document.getElementById('capacity_a').value = inst.capacity_a;
					}
					if(inst.capacity_e !== 0){
						document.getElementById('capacity_e').value = inst.capacity_e;
					}
					setIdVacancy(0);
				}
			}
		}
	}

	const [expand, setExpand] = useState(false);
	const [reload, setReload] = useState(false);

	function toggleExpand() {
		setExpand(!expand);
	}

	const defaultSorted = [
		{
			id: "name",
			desc: false
		}
	];
	const defaultSortedDesc = [
		{
			id: "dt",
			desc: true
		}
	];
	const tableColumns = [
		{
			Header: translations[session.language]['insts']['panel']['body']['table'].name,
			accessor: "name"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table'].email,
			accessor: "email"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table'].phone,
			accessor: "phone"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table'].local,
			accessor: "local"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table'].address,
			accessor: "tipo"
		},
		{
			id: 'button',
			Header: translations[session.language]['insts']['panel']['body']['table'].options,
			accessor: 'extra',
			width: 200,
			Cell: ({value}) => (
				<center>
					{value.tipo === 1 ? 
						<button className='btn btn-info btn-sm' onClick={() => loadSubData(value.id)} title={translations[session.language]['insts']['panel']['body']['table'].buttons.see}><i className="fa fa-search"></i></button>
					:
						''
					}
					{(session.permissions[2]['permission_type'][0]['permission'][2].hasPermission 
					? <button className='btn btn-warning btn-sm ml-1' onClick={() => toggleModal(value.id,1)} title={translations[session.language]['insts']['panel']['body']['table'].buttons.edit}><i className="fa fa-pencil-alt"></i></button>
						
					: '')}
					{(session.permissions[2]['permission_type'][0]['permission'][3].hasPermission 
					? <button className='btn btn-danger btn-sm ml-1' onClick={() => {setDeleteUser(value.id);setSweetAlertWarning(true)}} title={translations[session.language]['insts']['panel']['body']['table'].buttons.delete}><i className="fa fa-trash"></i></button>
					: '')}
				</center>
			)
		}
	];

	const tableColumnsSub = [
		{
			Header: translations[session.language]['insts']['panel']['body']['table2'].dt,
			accessor: "dt"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table2'].vacancy_a,
			accessor: "vacancy_a"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table2'].capacity_a,
			accessor: "capacity_a"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table2'].vacancy_e,
			accessor: "vacancy_e"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table2'].capacity_e,
			accessor: "capacity_e"
		},
		{
			id: 'button',
			Header: translations[session.language]['insts']['panel']['body']['table'].options,
			accessor: 'extra',
			width: 200,
			Cell: ({value}) => (
				<center>
					{(session.permissions[2]['permission_type'][0]['permission'][4].hasPermission 
					? 
						value.dt === new Date().toISOString().split('T')[0] 
						? 
							<button className='btn btn-warning btn-sm ml-1' onClick={() => toggleModal(value.id,2)} title={translations[session.language]['insts']['panel']['body']['table2'].buttons.edit}><i className="fa fa-pencil-alt"></i></button> 
						: 
						''
					: '')}
					{(session.permissions[2]['permission_type'][0]['permission'][7].hasPermission 
					? <button className='btn btn-danger btn-sm ml-1' onClick={() => {setDeleteVacancy(value.id);setSweetAlertWarningVacancy(true)}} title={translations[session.language]['insts']['panel']['body']['table2'].buttons.delete}><i className="fa fa-trash"></i></button>
					: '')}
				</center>
			)
		}
	];
	
	const tableColumnsRequests = [
		{
			Header: translations[session.language]['insts']['panel']['body']['table3'].id,
			accessor: "idRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table3'].inst,
			accessor: "userInstiturion"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table3'].nvacancies,
			accessor: "vagasRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table3'].type,
			accessor: "tipoRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table3'].dt,
			accessor: "dtRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table3'].state,
			accessor: "validateRequestText"
		},
		{
			id: 'button',
			Header: translations[session.language]['insts']['panel']['body']['table3'].options,
			width: 200,
			Cell: ({original}) => (
				<center>
					{
						(original.validateInst === 0 && original.validateRequest === 0) &&
						<>
							<button className='btn btn-success btn-sm ml-1' onClick={() =>{setSweetAlertWarningRequestAccept([true, original]);}} title={`${translations[session.language]['insts']['panel']['body']['table3'].buttons.aprove}`}><i className="fa fa-check"></i></button> 
							<button className='btn btn-danger btn-sm ml-1' onClick={() => {setSweetAlertWarningRequestRefuse([true, original]);}} title={`${translations[session.language]['insts']['panel']['body']['table3'].buttons.reprove}`}><i className="fa fa-times"></i></button>
						</>
					}
				</center>
			)
		}
	];

	const tableColumnsForwarding = [
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].id,
			accessor: "idRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].user,
			accessor: "usernNameRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].nvacancies,
			accessor: "vagasRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].type,
			accessor: "tipoRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].dt,
			accessor: "dtRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].state,
			accessor: "validateRequestText"
		},
		{
			id: 'button',
			Header: translations[session.language]['insts']['panel']['body']['table4'].options,
			width: 200,
			Cell: ({original}) => (
				<center>
					{
						original.validateRequest === 0 &&
						<button className='btn btn-danger btn-sm ml-1' onClick={() =>{setSweetAlertWarningForwardingRefuse([true, original]);}} title={`${translations[session.language]['insts']['panel']['body']['table4'].buttons.cancel}`}><i className="fa fa-trash"></i></button> 
					}
				</center>
			)
		}
	];

	const [sweetAlertWarningRequestAccept, setSweetAlertWarningRequestAccept] = useState([false, 0])
	async function handleAccetRequest(data){
		try {
			await api.put(`/inst-request/${data.idInst}/${data.idRequest}/${session.id}/1`)
			.then(async (response) => {
				setSuccessMessage(translations[session.language]['insts']['responses']['aproverequest'].success);
				setSweetAlertSuccess(true);
				await loadData()
			})
			.catch(error => {
				if(error.response.status === 422) {
					setErrorMessage(translations[session.language]['insts']['responses']['aproverequest'][error.response.data.code]);
				    setSweetAlertError(true);
				}else{
					setErrorMessage('ERROR');
					setSweetAlertError(true);
				}
			});
		} catch (err) {
			setErrorMessage('ERROR');
			setSweetAlertError(true);
		}
	}

	const [sweetAlertWarningRequestRefuse, setSweetAlertWarningRequestRefuse] = useState([false, 0])
	async function handleRefuseRequest(data) {
		try {
			await api.put(`/inst-request/${data.idInst}/${data.idRequest}/${session.id}/2`)
			.then(async (response) => {
				setSuccessMessage(translations[session.language]['insts']['responses']['reproverequest'].success);
				setSweetAlertSuccess(true);
				await loadData()
			});
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}

	const [observationRefuse, setObservationRefuse] = useState('')
	const [sweetAlertWarningForwardingRefuse, setSweetAlertWarningForwardingRefuse] = useState([false, 0]);
	async function handleRefuseForwarding(data) {
		console.log(data)
		try {
			await api.put(`/inst-forwarding/${data.idInst}/${data.idRequest}/${session.id}/3`, {observation: observationRefuse})
			.then(async (response) => {
				setSuccessMessage(translations[session.language]['insts']['responses']['cancelrequest'].success);
				setSweetAlertSuccess(true);
				await loadData()
			})
			.catch(error => {
				if(error.response.status === 422) {
					setErrorMessage(translations[session.language]['insts']['responses']['cancelrequest'][error.response.data.code]);
				    setSweetAlertError(true);
				}else{
					setErrorMessage('ERROR');
					setSweetAlertError(true);
				}
			});
		} catch (err) {
			setErrorMessage('ERROR');
			setSweetAlertError(true);
		}
	}

	useEffect(() => {
		loadData();
		(async () => {
			try {
				await api.get('/inst-district')
				.then(response => {
					setInstDistrict(response.data);
				});
				await api.get('/inst-city')
				.then(response => {
					setInstCity(response.data);
				});
				await api.get('/inst-orig')
				.then(response => {
					setInstOrig(response.data);
				});
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
			setCurrentInstCity([{id: 0, name: translations[session.language]['insts']['modal']['body']['district'].placeholder}]);
		})();
	}, []);

	
	async function loadData() {
		setReload(true);
		console.log("type: ", type)
		if(type === 3){
			let inst = [];
			let insts = [];
			try {
				await api.get('/inst')
				.then(response => {
					insts = response.data;
					inst = response.data.find(x => x.id === session.idinst);
				});
				setInsts(insts);
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
			try {
				await api.get(`/inst-vacancies/${inst.id}`)
				.then(response => {
					let vagas = response.data;
					let lastdt = "";
					for(let i = 0;i < vagas.length;i++){
						const dt = vagas[i]['dt'].split("T");
						vagas[i]['dt'] = dt[0];
						vagas[i]['extra'] = {id: vagas[i]['id'],dt: dt[0]}
						lastdt = dt[0];
					}
					if(lastdt !==  new Date().toISOString().split('T')[0]){
						setSweetAlertWarningStart(true)
					}
					setReload(false);
					setVacancies(vagas);
					setIsMain(false);
					setId(inst.id);
				});
				// GET LIST REQUESTS
				await api.get(`/inst-requests/${inst.id}/0`)
				.then(response => {
					setRequests(response.data)
				});

				// GET LIST FORWARDINGS
				// await api.get(`/inst-forwardings/${inst.id}/0`)
				// .then(response => {
				// 	setForwardings(response.data)
				// });
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}else{
			try {
				await api.get('/inst')
				.then(response => {
					const inst = response.data;
					// let newinst = [];
					// for(let i = 0;i < inst.length;i++){
					// 	if(inst[i]['ident'] === 3){
					// 		newinst[newinst.length] = inst[i];
					// 	}
					// }
					setInsts(inst);
					setInstsFiltered(inst);
				});
				await api.get('/users')
				.then(response => {
					const util = response.data;
					let newutil = [];
					for(let i = 0;i < util.length;i++){
						if(util[i]['ident'] === 3){
							newutil[newutil.length] = util[i];
						}
					}
					setUsers(newutil);
				});
				
				setReload(false);
				setCurrentInstCity([{id: 0, name: translations[session.language]['insts']['modal']['body']['district'].placeholder}]);
				setIsMain(true);
				setId(0);
				setVacancies([]);
				let pesquisa = document.getElementById('search').value;
				let filter;
				if(pesquisa){
					let results = [];
					if (pesquisa.indexOf(' ') !== -1) {
						filter = pesquisa.split(' ');
						filter.indexOf("") !== -1 && filter.splice(filter.indexOf(""),1);
						for(let a = 0;a < insts.length;a++){
							for(let b = 0;b < filter.length;b++){
								if(insts[a].name.toLowerCase().includes(filter[b]) || insts[a].email.toLowerCase().includes(filter[b]) || insts[a].phone.toLowerCase().includes(filter[b]) || insts[a].local.toLowerCase().includes(filter[b]) || insts[a].tipo.toLowerCase().includes(filter[b])){
									results[results.length] = insts[a];
									break;
								}
							}
						}
					}else{
						for(let a = 0;a < insts.length;a++){
							if(insts[a].name.toLowerCase().includes(pesquisa) || insts[a].email.toLowerCase().includes(pesquisa) || insts[a].phone.toLowerCase().includes(pesquisa) || insts[a].local.toLowerCase().includes(pesquisa) || insts[a].tipo.toLowerCase().includes(pesquisa)){
								results[results.length] = insts[a];
							}
						}
					}
					setInstsFiltered(results);
				}
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}

	async function loadSubData(idinst) {
		setReload(true);
		try {
			await api.get(`/inst-vacancies/${idinst}`)
			.then(response => {
				let vagas = response.data;
				for(let i = 0;i < vagas.length;i++){
					const dt = vagas[i]['dt'].split("T");
					vagas[i]['dt'] = dt[0];
					vagas[i]['extra'] = {id: vagas[i]['id'],dt: dt[0]}
				}
				setReload(false);
				setVacancies(vagas);
				setIsMain(false);
				setId(idinst);
			});

			// GET LIST REQUESTS
			await api.get(`/inst-requests/${idinst}/0`)
			.then(response => {
				setRequests(response.data)
			});

			// GET LIST FORWARDINGS
			// await api.get(`/inst-forwardings/${idinst}/0`)
			// .then(response => {
			// 	setForwardings(response.data)
			// });
			
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}
	
	async function handleNewInst(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";
		const ident = document.getElementById('idEntidade').value;
		if (ident && ident !== "0") {
			data.ident = ident;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['user-type'];
		}

		const idcity = document.getElementById('idCity').value;
		if (idcity && idcity !== 0) {
			data.idcity = idcity;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['city'];
		}

		const name = document.getElementById('name').value;
		if (name && name !== '') {
			data.name = name;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['name'];
		}

		const email = document.getElementById('email').value;
		if (email && validateEmail(email)) {
			data.email = email;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['email'];
		}

		const address = document.getElementById('address').value;
		if (address && address !== '') {
			data.address = address;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['address'];
		}

		const postalcode = document.getElementById('postalcode').value;
		if (postalcode && postalcode !== '') {
			data.postalcode = postalcode;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['postalcode'];
		}

		const phone = document.getElementById('phone').value;
		if (phone && phone !== '') {
			data.phone = phone;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['phone'];
		}
		
		if(parseInt(ident) === 1){
			const latitude = document.getElementById('latitude').value;
			if (latitude && latitude !== '') {
				data.latitude = latitude;
			} else {
				data.latitude = '';
			}
			const longitude = document.getElementById('longitude').value;
			if (longitude && longitude !== '') {
				data.longitude = longitude;
			} else {
				data.longitude = '';
			}
			
			const capacity_a = document.getElementById('capacity_a').value;
			if (capacity_a && capacity_a !== 0) {
				data.capacity_a = capacity_a;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_a'];
			}

			const capacity_e = document.getElementById('capacity_e').value;
			if (capacity_e && capacity_e !== 0) {
				data.capacity_e = capacity_e;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_e'];
			}

			const showInfo = document.getElementById('showInfo');
			if (showInfo.checked === true) {
				data.showinfo = 1;
			} else {
				data.showinfo = 2;
			}
		}else{
			data.capacity_a = 0;
			data.capacity_e = 0;
			data.showinfo = 1;
			data.latitude = '';
			data.longitude = '';
		}
		
		data.iduser = session.id;
		data.userType = ident;

		if (val !== 0) {
			console.log("Erro:" + msg);
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {	
			try {
				await api.post('/inst', data)
				.then(response => {
					setModalDialog(false);
					setSuccessMessage(translations[session.language]['insts']['responses']['create'][response.data]);
					setSweetAlertSuccess(true);
					loadData();
				})
				.catch(err => {
					setErrorMessage(translations[session.language]['insts']['responses']['create'][err.response.data]);
					setSweetAlertError(true);
				})
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}

	async function handleEditInst(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";
		const ident = document.getElementById('idEntidade').value;
		if (ident && ident !== "0") {
			data.ident = ident;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['user-type'];
		}

		const idcity = document.getElementById('idCity').value;
		if (idcity && idcity !== 0) {
			data.idcity = idcity;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['city'];
		}

		const name = document.getElementById('name').value;
		if (name && name !== '') {
			data.name = name;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['name'];
		}

		const email = document.getElementById('email').value;
		if (email && validateEmail(email)) {
			data.email = email;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['email'];
		}

		const address = document.getElementById('address').value;
		if (address && address !== '') {
			data.address = address;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['address'];
		}

		const postalcode = document.getElementById('postalcode').value;
		if (postalcode && postalcode !== '') {
			data.postalcode = postalcode;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['postalcode'];
		}

		const phone = document.getElementById('phone').value;
		if (phone && phone !== '') {
			data.phone = phone;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['phone'];
		}
		if(parseInt(ident) === 1){
			const latitude = document.getElementById('latitude').value;
			if (latitude && latitude !== '') {
				data.latitude = latitude;
			} else {
				data.latitude = '';
			}
			const longitude = document.getElementById('longitude').value;
			if (longitude && longitude !== '') {
				data.longitude = longitude;
			} else {
				data.longitude = '';
			}
			const capacity_a = document.getElementById('capacity_a').value;
			if (capacity_a && capacity_a !== 0) {
				data.capacity_a = capacity_a;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_a'];
			}

			const capacity_e = document.getElementById('capacity_e').value;
			if (capacity_e && capacity_e !== 0) {
				data.capacity_e = capacity_e;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_e'];
			}

			const showInfo = document.getElementById('showInfo');
			if (showInfo.checked === true) {
				data.showinfo = 1;
			} else {
				data.showinfo = 2;
			}
		}else{
			data.capacity_a = 0;
			data.capacity_e = 0;
			data.showinfo = 1;
			data.latitude = '';
			data.longitude = '';
		}
		
		data.iduser = session.id;
		data.userType = ident;
		if (val !== 0) {
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {
			try {
				await api.put(`/inst/${id}`, data)
				.then(response => {
					setModalDialog(false);
					setSuccessMessage(translations[session.language]['insts']['responses']['edit'][response.data]);
					setSweetAlertSuccess(true);
					loadData();
				})
				.catch(err => {
					setErrorMessage(translations[session.language]['insts']['responses']['edit'][err.response.data]);
					setSweetAlertError(true);
				})
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}
	async function handleNewVacancy(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";
		data.idinst = id;
		const checkCapA =  document.getElementById('capacity_a');
		if (typeof(checkCapA) != 'undefined' && checkCapA != null){
			const vacancy_a = parseFloat(document.getElementById('vacancy_a').value);
			if (vacancy_a || vacancy_a === 0) {
				data.vacancy_a = vacancy_a;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['vacancy_a'];
			}

			const capacity_a = parseFloat(document.getElementById('capacity_a').value);
			if (capacity_a || capacity_a === 0) {
				data.capacity_a = capacity_a;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_a'];
			}
		}else{
			data.vacancy_a = 0;
			data.capacity_a = 0;
		}
		const checkCapE =  document.getElementById('capacity_e');
		if (typeof(checkCapE) != 'undefined' && checkCapE != null){
			const vacancy_e = parseFloat(document.getElementById('vacancy_e').value);
			if (vacancy_e || vacancy_e === 0) {
				data.vacancy_e = vacancy_e;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['vacancy_e'];
			}

			const capacity_e = parseFloat(document.getElementById('capacity_e').value);
			if (capacity_e || capacity_e === 0) {
				data.capacity_e = capacity_e;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_e'];
			}
		}else{
			data.vacancy_e = 0;
			data.capacity_e = 0;
		}

		const dt = document.getElementById('dt').value;
		if (dt && dt !== '') {
			data.dt = dt;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['dt'];
		}

		data.iduser = session.id

		if (val !== 0) {
			console.log("Erro:" + msg);
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {
			try {
				await api.post('/inst-vacancies', data)
				.then(response => {
					setModalDialog(false);
					setSuccessMessage(translations[session.language]['insts']['responses']['createvacancy'][response.data]);
					setSweetAlertSuccess(true);
					loadSubData(id);
				})
				.catch(err => {
					setErrorMessage(translations[session.language]['insts']['responses']['createvacancy'][err.response.data]);
					setSweetAlertError(true);
				})
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}

	async function handleEditVacancy(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";
		data.idinst = id;
		const checkCapA =  document.getElementById('capacity_a');
		if (typeof(checkCapA) != 'undefined' && checkCapA != null){
			const vacancy_a = parseFloat(document.getElementById('vacancy_a').value);
			if (vacancy_a || vacancy_a === 0) {
				data.vacancy_a = vacancy_a;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['vacancy_a'];
			}

			const capacity_a = parseFloat(document.getElementById('capacity_a').value);
			if (capacity_a || capacity_a === 0) {
				data.capacity_a = capacity_a;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_a'];
			}
		}else{
			data.vacancy_a = 0;
			data.capacity_a = 0;
		}

		const checkCapE =  document.getElementById('capacity_e');
		if (typeof(checkCapE) != 'undefined' && checkCapE != null){
			const vacancy_e = parseFloat(document.getElementById('vacancy_e').value);
			if (vacancy_e || vacancy_e === 0) {
				data.vacancy_e = vacancy_e;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['vacancy_e'];
			}

			const capacity_e = parseFloat(document.getElementById('capacity_e').value);
			if (capacity_e || capacity_e === 0) {
				data.capacity_e = capacity_e;
			} else {
				val++;
				msg += translations[session.language]['insts']['modal']['validations']['capacity_e'];
			}
		}else{
			data.vacancy_e = 0;
			data.capacity_e = 0;
		}

		const dt = document.getElementById('dt').value;
		if (dt && dt !== '') {
			data.dt = dt;
		} else {
			val++;
			msg += translations[session.language]['insts']['modal']['validations']['dt'];
		}

		data.iduser = session.id
		
		if (val !== 0) {
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {
			try {
				await api.put(`/inst-vacancies/${idvacancy}`, data)
				.then(response => {
					setModalDialog(false);
					setSuccessMessage(translations[session.language]['insts']['responses']['editvacancy'][response.data]);
					setSweetAlertSuccess(true);
					loadSubData(id);
				})
				.catch(err => {
					setErrorMessage(translations[session.language]['insts']['responses']['editvacancy'][err.response.data]);
					setSweetAlertError(true);
				})
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}

	const [deleteUser, setDeleteUser] = useState(0);
	const [deleteVacancy, setDeleteVacancy] = useState(0);
	async function handleDeleteInst(id) {
		try {
			await api.delete(`/inst/${id}`)
			.then(response => {
				setSuccessMessage(translations[session.language]['insts']['responses']['deletevacancy'][response.data]);
				setSweetAlertSuccess(true);
				loadData();
			})
			.catch(err => {
				setErrorMessage(translations[session.language]['insts']['responses']['deletevacancy'][err.response.data]);
				setSweetAlertError(true);
			})
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}

	async function handleDeleteVacancy(id) {
		try {
			await api.delete(`/inst-vacancies/${id}`)
			.then(response => {
				setSuccessMessage(translations[session.language]['insts']['responses']['deletevacancy'][response.data]);
				setSweetAlertSuccess(true);
				loadData();
			})
			.catch(err => {
				setErrorMessage(translations[session.language]['insts']['responses']['deletevacancy'][err.response.data]);
				setSweetAlertError(true);
			})
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}
	function handleDistrictChange(e) {
		const idDistrict = e.target.value;
		if(idDistrict !== "0"){
			let cities = [];
			cities[0] = {id: 0, name: translations[session.language]['insts']['modal']['body']['city'].placeholder};
			instCity.map(city => parseInt(city.iddistrict) === parseInt(idDistrict) && (
				cities[cities.length] = city
			)
			);
			setCurrentInstCity(cities);
		}else{
			setCurrentInstCity([{id: 0, name: translations[session.language]['insts']['modal']['body']['district'].placeholder}]);
		}
	}
	function handleEntityChange(e) {
		const idEntity = parseInt(e.target.value);
		setHasEntity(idEntity);
		if(idEntity !== 1 && idEntity !== 2){
			document.getElementById('name').value = e.target.options[e.target.selectedIndex].text;
		}
	}
	function handleChangeMax(e){
		const vacancy = e.target.value;
		const ext = e.target.id.split("_")[1];
		if(parseInt(vacancy) > parseInt(document.getElementById('capacity_'+ext).value)){
			document.getElementById(e.target.id).value = parseInt(document.getElementById('capacity_'+ext).value);
		}
	}
	function handleFilter(e){
		const pesquisa = e.target.value.toLowerCase();
		let filter;
		if(pesquisa === ''){
			setInstsFiltered(insts);
		}else{
			let results = [];
			if (pesquisa.indexOf(' ') !== -1) {
				filter = pesquisa.split(' ');
				filter.indexOf("") !== -1 && filter.splice(filter.indexOf(""),1);
				for(let a = 0;a < insts.length;a++){
					for(let b = 0;b < filter.length;b++){
						if(insts[a].name.toLowerCase().includes(filter[b]) || insts[a].email.toLowerCase().includes(filter[b]) || insts[a].phone.toLowerCase().includes(filter[b]) || insts[a].local.toLowerCase().includes(filter[b]) || insts[a].tipo.toLowerCase().includes(filter[b])){
							results[results.length] = insts[a];
							break;
						}
					}
				}
			}else{
				for(let a = 0;a < insts.length;a++){
					if(insts[a].name.toLowerCase().includes(pesquisa) || insts[a].email.toLowerCase().includes(pesquisa) || insts[a].phone.toLowerCase().includes(pesquisa) || insts[a].local.toLowerCase().includes(pesquisa) || insts[a].tipo.toLowerCase().includes(pesquisa)){
						results[results.length] = insts[a];
					}
				}
			}
			setInstsFiltered(results);
		}
	}
	let conteudo = "";
	if(isMain){
		conteudo = <div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
			<div className="panel-heading">
				<h4 className="panel-title">{translations[session.language]['insts']['panel'].title}</h4>
				<div className="panel-heading-btn">
					{(session.permissions[2]['permission_type'][0]['permission'][1].hasPermission || session.permissions[2]['permission_type'][0]['permission'][2].hasPermission 
					? <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0,1)}><i className="fa fa-plus"></i></button>
					: '')}
					&nbsp;&nbsp;<button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => loadData()}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
					<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
				</div>
			</div>
			<div className="panel-body row">
				<div className='col-md-12'>
					<div className="form-group row m-b-15 pull-right">
						<label className="col-form-label col-md-3">Procurar:</label>
						<div className="col-md-9">
							<input type="text" id="search" className="form-control" onChange={(e) => handleFilter(e)}/>
						</div>
					</div>
				</div>
				<div className='col-md-12'>
				<ReactTable data={instsFiltered} columns={tableColumns} defaultPageSize={10} defaultSorted={defaultSorted} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
				{(reload && 
					<div className="panel-loader">
						<span className="spinner-small"></span>
					</div>
				)}
				</div>
			</div>
		</div>
	}else{
		conteudo = 
		<>
			<div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
				<div className="panel-heading">
					<h4 className="panel-title">Listagem de Pedidos</h4>
					<div className="panel-heading-btn">
						{type === 3 ? '' : <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => loadData()}><i className="fa fa-reply"></i></button>}
						&nbsp;&nbsp;
						{/* {(session.permissions[2]['permission_type'][0]['permission'][4].hasPermission
						? <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0,2)}><i className="fa fa-plus"></i></button>
						: '')} */}
						&nbsp;&nbsp;<button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => type === 3 ? loadData() : loadSubData(id)}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
						<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
					</div>
				</div>
				<div className="panel-body">
					<ReactTable data={requests} columns={tableColumnsRequests} defaultPageSize={5} defaultSorted={defaultSortedDesc} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
					{(reload && 
						<div className="panel-loader">
							<span className="spinner-small"></span>
						</div>
					)}
				</div>
			</div>
			{/* <div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
				<div className="panel-heading">
					<h4 className="panel-title">Listagem de Pedidos Efetuados</h4>
					<div className="panel-heading-btn">
						{type === 3 ? '' : <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => loadData()}><i className="fa fa-reply"></i></button>}
						&nbsp;&nbsp;
						&nbsp;&nbsp;<button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => type === 3 ? loadData() : loadSubData(id)}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
						<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
					</div>
				</div>
				<div className="panel-body">
					<ReactTable data={forwardings} columns={tableColumnsForwarding} defaultPageSize={5} defaultSorted={defaultSortedDesc} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
					{(reload && 
						<div className="panel-loader">
							<span className="spinner-small"></span>
						</div>
					)}
				</div>
			</div> */}
			<div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
				<div className="panel-heading">
					<h4 className="panel-title">{translations[session.language]['insts']['panel'].title2}</h4>
					<div className="panel-heading-btn">
						{type === 3 ? '' : <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => loadData()}><i className="fa fa-reply"></i></button>}
						&nbsp;&nbsp;
						{(session.permissions[2]['permission_type'][0]['permission'][4].hasPermission
						? <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0,2)}><i className="fa fa-plus"></i></button>
						: '')}
						&nbsp;&nbsp;<button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => type === 3 ? loadData() : loadSubData(id)}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
						<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
					</div>
				</div>
				<div className="panel-body">
					<ReactTable data={vacancies} columns={tableColumnsSub} defaultPageSize={10} defaultSorted={defaultSortedDesc} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
					{(reload && 
						<div className="panel-loader">
							<span className="spinner-small"></span>
						</div>
					)}
				</div>
			</div>
		</>
	}
	let disabled = true;
	let currentModal = "";
	if(session.type === 1 || session.type === 2){
		disabled = false;
	}
	if(modalEsc === 1){
		currentModal = <form onSubmit={id === 0 ? handleNewInst : handleEditInst}>
			<ModalBody>
				{/*session.permissions[2]['permission_type'][0]['permission'][1].hasPermission 
				&& () */}
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['user-type'].title}</label>
					<div className="col-md-9">
						<select className="form-control" id="idEntidade" onChange={(e) => handleEntityChange(e)}>
							<option value={0}>{translations[session.language]['insts']['modal']['body']['user-type'].placeholder}</option>
							{
								instOrig.map(orig => (
									<option key={orig.id} value={orig.id}>{orig.description}</option>
								))
							}
						</select>
					</div>
				</div>
				
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['name'].title}</label>
					<div className="col-md-9">
						<input type="text" id="name" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['name'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['email'].title}</label>
					<div className="col-md-9">
						<input type="email" id="email" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['email'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['address'].title}</label>
					<div className="col-md-9">
						<input type="text" id="address" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['address'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['postalcode'].title}</label>
					<div className="col-md-9">
						<input type="text" id="postalcode" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['postalcode'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['district'].title}</label>
					<div className="col-md-9">
						<select className="form-control" id="idDistrict" onChange={(e) => handleDistrictChange(e)}>
							<option value={0}>{translations[session.language]['insts']['modal']['body']['district'].placeholder}</option>
							{
								instDistrict.map(district => (
									<option key={district.id} value={district.id}>{district.name}</option>
								))
							}
						</select>
					</div>
				</div>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['city'].title}</label>
					<div className="col-md-9">
						<select className="form-control" id="idCity">
						{
							currentInstCity.map(currentCity => (
								<option key={currentCity.id} value={currentCity.id}>{currentCity.name}</option>
							))
						}
						</select>
					</div>
				</div>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['phone'].title}</label>
					<div className="col-md-9">
						<input type="text" id="phone" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['phone'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15" style={(parseInt(hasEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['latitude'].title}</label>
					<div className="col-md-9">
						<input type="text" id="latitude" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['latitude'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15" style={(parseInt(hasEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['longitude'].title}</label>
					<div className="col-md-9">
						<input type="text" id="longitude" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['longitude'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15" style={(parseInt(hasEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['capacity_a'].title}</label>
					<div className="col-md-9">
						<input type="number" id="capacity_a" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['capacity_a'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15" style={(parseInt(hasEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['capacity_e'].title}</label>
					<div className="col-md-9">
						<input type="number" id="capacity_e" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['capacity_e'].placeholder} />
					</div>
				</div>
				<div className="form-group row m-b-15" style={(parseInt(hasEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['showinfo'].title}</label>
					<div className="col-md-9">
						<input type="checkbox" id="showInfo" className="form-control" />
					</div>
				</div>
				{/*<div className="form-group row m-b-15" style={(id === 0) ? { display: 'none' } : { display: 'flex' }}>
					<div className="col-md-12">
						<button type="button" id="btnPassword" className="btn btn-warning pull-right" onClick={() => {
							document.getElementById('btnPassword').style.display = 'none';
							document.getElementById('passwordDiv').style.display = 'block';
						}}>Alterar Password</button>
					</div>
				</div>
				<div id="passwordDiv" style={(hasEntity === false) ? (id === 0) ? { display: 'flex' } : { display: 'none' } : { display: 'none' }}>
					<div className="form-group row m-b-15">
						<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['password'].title}</label>
						<div className="col-md-9">
							<input type="password" id="password" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['password'].placeholder} />
						</div>
					</div>
					<div className="form-group row m-b-15">
						<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['password-confirmation'].title}</label>
						<div className="col-md-9">
							<input type="password" id="passwordConfirmation" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['password-confirmation'].placeholder} />
						</div>
					</div>
					</div>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-2">{translations[session.language]['insts']['modal']['body']['photo'].title}</label>
					<div className="col-md-10">
						<input type="file" className="form-control" id="file"
							onChange={(e) => {
								const image = e.target.files[0];
								
								if (validateImage(image)) {
									if (image.size > (3 * 1024 * 1024)) {
										setErrorMessage(translations[session.language]['insts']['modal']['body']['photo']['size']);
										setSweetAlertError(true);
									}
								} else {
									document.getElementById('file').value = null;
									setErrorMessage(translations[session.language]['utils']['validateImage']['extension']);
									setSweetAlertError(true);
								}
							}}
						/>
					</div>
				</div>*/}
			</ModalBody>
			<ModalFooter>
				<button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0,1)}>{translations[session.language]['insts']['modal']['footer'].close}</button>
				<button type="submit" className="btn btn-primary">{translations[session.language]['insts']['modal']['footer'].save}</button>
			</ModalFooter>
		</form>
	}else if(modalEsc === 2){
		currentModal = <form onSubmit={idvacancy === 0 ? handleNewVacancy : handleEditVacancy}>
			<ModalBody>
				<div className="form-group row m-b-15">
					<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['dt'].title}</label>
					<div className="col-md-9">
						<input type="date" id="dt" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['dt'].placeholder}/>
					</div>
				</div>
				{capacityA !== 0 
					?
					<div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['vacancy_a'].title}</label>
							<div className="col-md-9">
								<input type="number" id="vacancy_a" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['vacancy_a'].placeholder} onChange={(e) => handleChangeMax(e)}/>
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['capacity_a'].title}</label>
							<div className="col-md-9">
								<input type="number" id="capacity_a" className="form-control" disabled={disabled} placeholder={translations[session.language]['insts']['modal']['body']['capacity_a'].placeholder}/>
							</div>
						</div>
					</div>
					:
						''
				}
				{capacityE !== 0 
					?
					<div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['vacancy_e'].title}</label>
							<div className="col-md-9">
								<input type="number" id="vacancy_e" className="form-control" placeholder={translations[session.language]['insts']['modal']['body']['vacancy_e'].placeholder} onChange={(e) => handleChangeMax(e)}/>
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['insts']['modal']['body']['capacity_e'].title}</label>
							<div className="col-md-9">
								<input type="number" id="capacity_e" className="form-control" disabled={disabled} placeholder={translations[session.language]['insts']['modal']['body']['capacity_e'].placeholder}/>
							</div>
						</div>	
					</div>	
					:
						''
				}		
			</ModalBody>
			<ModalFooter>
				<button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0,2)}>{translations[session.language]['insts']['modal']['footer'].close}</button>
				<button type="submit" className="btn btn-primary">{translations[session.language]['insts']['modal']['footer'].save}</button>
			</ModalFooter>
		</form>
	}
	return (
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
				<li className="breadcrumb-item active">{translations[session.language]['insts'].title}</li>
			</ol>
			<h1 className="page-header">{translations[session.language]['insts'].title}</h1>
			{conteudo}


			<Modal isOpen={modalDialog} toggle={() => toggleModal(0,modalEsc)}>
				<ModalHeader toggle={() => toggleModal(0,modalEsc)}>
					{
						modalEsc === 1 ? 
							id === 0 ? 
							translations[session.language]['insts']['modal']['header']['add'] : 
							translations[session.language]['insts']['modal']['header']['edit']
						:
							idvacancy === 0 ?
							translations[session.language]['insts']['modal']['header']['addvacancy'] : 
							translations[session.language]['insts']['modal']['header']['editvacancy']
					}
				</ModalHeader>
				{currentModal}
			</Modal>
			{(sweetAlertSuccess &&
				<SweetAlert success
					confirmBtnText="OK"
					confirmBtnBsStyle="success"
					title={translations[session.language]['alerts']['success'].title}
					onConfirm={() => setSweetAlertSuccess(false)}
				>
					{successMessage}
				</SweetAlert>
			)}
			{(sweetAlertWarningRequestAccept[0] &&
				<SweetAlert warning showCancel
					confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
					cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title={translations[session.language]['alerts']['warning'].title}
					onConfirm={() => {handleAccetRequest(sweetAlertWarningRequestAccept[1]);setSweetAlertWarningRequestAccept([false, 0])}}
					onCancel={() => setSweetAlertWarningRequestAccept([false, 0])}
				>
					{translations[session.language]['insts']['alerts']['warning'].message2}
				</SweetAlert>
			)}
			{(sweetAlertWarningRequestRefuse[0] &&
				<SweetAlert warning showCancel
					confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
					cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title={translations[session.language]['alerts']['warning'].title}
					onConfirm={() => {handleRefuseRequest(sweetAlertWarningRequestRefuse[1]);setSweetAlertWarningRequestRefuse([false, 0])}}
					onCancel={() => setSweetAlertWarningRequestRefuse([false, 0])}
				>
					{translations[session.language]['insts']['alerts']['warning'].message3}
				</SweetAlert>
			)}

			{(sweetAlertWarningForwardingRefuse[0] &&
				<SweetAlert warning showCancel
					confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
					cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title={translations[session.language]['alerts']['warning'].title}
					onConfirm={() => {handleRefuseForwarding(sweetAlertWarningForwardingRefuse[1]);setSweetAlertWarningForwardingRefuse([false, 0])}}
					onCancel={() => setSweetAlertWarningForwardingRefuse([false, 0])}
				>
					{translations[session.language]['insts']['alerts']['warning'].message4}
					<div className="form-group row m-b-15">
						<label className="col-form-label col-md-12" style={{fontWeight: 'bold'}}>Observações</label>
						<div className="col-md-12">
							<textarea className="form-control" value={observationRefuse} onChange={(e) => setObservationRefuse(e.target.value)}/>
						</div>
					</div>
				</SweetAlert>
			)}
			{(sweetAlertWarning &&
					<SweetAlert warning showCancel
						confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
						cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="default"
						title={translations[session.language]['alerts']['warning'].title}
						onConfirm={() => {handleDeleteInst(deleteUser);setSweetAlertWarning(false)}}
						onCancel={() => setSweetAlertWarning(false)}
					>
						{translations[session.language]['insts']['alerts']['warning'].message1}
					</SweetAlert>
				)}
			{(sweetAlertWarningVacancy &&
					<SweetAlert warning showCancel
						confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
						cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="default"
						title={translations[session.language]['alerts']['warning'].title}
						onConfirm={() => {handleDeleteVacancy(deleteVacancy);setSweetAlertWarningVacancy(false)}}
						onCancel={() => setSweetAlertWarningVacancy(false)}
					>
						Tem a certeza que pretende eliminar este Registo de Vagas?
					</SweetAlert>
				)}
			{(sweetAlertWarningStart &&
				<SweetAlert warning showCancel
					confirmBtnText="Prosseguir"
					cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
					confirmBtnBsStyle="info"
					cancelBtnBsStyle="default"
					title="Falta de Atualização!"
					onConfirm={() => {toggleModal(0,2);setSweetAlertWarningStart(false)}}
					onCancel={() => setSweetAlertWarningStart(false)}
				>
					Como ainda não efectuou registo para o dia de hoje, deseja prosseguir para o registo?
				</SweetAlert>
			)}
			{(sweetAlertError &&
					<SweetAlert danger
							confirmBtnText="OK"
							confirmBtnBsStyle="default"
							title={translations[session.language]['alerts']['error'].title}
							onConfirm={() => setSweetAlertError(false)}
					>
						{errorMessage}
					</SweetAlert>
			)}
		</div>
	)
}