import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { PageSettings } from './../../config/page-settings.js';
import jwt_decode from 'jwt-decode';

import translations from '../../pages/translations.json';

class SidebarProfile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			profileActive: 0,
			name: null,
			description: null,
		};
		this.handleProfileExpand = this.handleProfileExpand.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
	}

	componentDidMount() {
		const token = localStorage.token ? localStorage.token : '';
		if (localStorage.token) {
			const decode = jwt_decode(token);
			this.setState({
				name: decode.name,
				description: decode.description,
			});
		}
	}

	handleProfileExpand(e) {
		e.preventDefault();
		this.setState(state => ({
			profileActive: !this.state.profileActive,
		}));
	}

	handleLogout() {
		localStorage.removeItem('token');
		this.props.history.push('/');
	}
  
	render() {
		const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt';
		const { name, description } = this.state;
		return (
			<PageSettings.Consumer>
				{({pageSidebarMinify}) => (
					<ul className="nav">
						<li className={"nav-profile " + (this.state.profileActive ? "expand " : "")}>
							<Link to="/" onClick={this.handleProfileExpand}>
								<div className="cover with-shadow" style={{backgroundImage: 'url(/assets/img/login-bg/login-bg-6.jpg)'}}></div>
								<div className="image">
								</div>
								<div className="info">
									<b className="caret pull-right"></b>
									{name}
									<small>{description}</small>
								</div>
							</Link>
						</li>
						<li>
							<ul className={"nav nav-profile " + (this.state.profileActive && !pageSidebarMinify ? "d-block " : "")}>
								<li><Link to="/profile"><i className="fa fa-pencil-alt"></i> {translations[language]['components']['sidebar']['edit-profile']}</Link></li>
								<li><Link to="#" onClick={this.handleLogout}><i className="fa fa-sign-out-alt"></i> Logout</Link></li>
							</ul>
						</li>
					</ul>
				)}
			</PageSettings.Consumer>
		)
	}
}

export default withRouter(SidebarProfile);