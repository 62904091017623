export default function validateImage(image) {
  const allowedMimes = [
    'image/jpg',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
  ];

  if(allowedMimes.includes(image.type)){
    return true;
  }
  return false;
}