import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import logout from '../../../utils/logout';
import jwt_decode from 'jwt-decode';
import api from '../../../services/api';

export default function DropdownLanguage() {
	const [sweetAlertError, setSweetAlertError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	
	var language = 'pt';
	
	try {
		({ language } = jwt_decode(localStorage.token));
	} catch (err) {
		//console.error(err);
	}
	
	const [dropdownOpen, setDropdownOpen] = useState(false);

	function toggle() {
		setDropdownOpen(!dropdownOpen);
	}

	async function changeLanguage(lang) {
		try {
			const { id } = jwt_decode(localStorage.token);

			const data = {
				language: lang
			}
			await api.put(`/profile-language/${id}`, data, {
				headers: {
					token: localStorage.token
				}
			})
			.then(response => {
				localStorage.setItem('token', response.data.token);
				window.location.reload();
			}).catch(err => {
				if (err.response.status === 401) {
					setErrorMessage("Sessão inválida, por favor faça login novamente.");
				} else {
					setErrorMessage(err.response.data.message);
				}
				setSweetAlertError(true);
			});
		} catch (err) {
			setErrorMessage("Sessão inválida, por favor faça login novamente.");
			setSweetAlertError(true);
		}
	}
  
	return (
		<>
		<Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-languager" tag="li" style={{display: "none"}}>
			{
				language === "pt" ? 
				<DropdownToggle className="dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
					<span className="flag-icon flag-icon-pt m-r-5" title="pt"></span>
					<span className="name d-none d-sm-inline">PT</span>
				</DropdownToggle> : language === "en" ? 
				<DropdownToggle className="dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
					<span className="flag-icon flag-icon-gb m-r-5" title="en"></span>
					<span className="name d-none d-sm-inline">EN</span>
				</DropdownToggle> : language === "fr" ?
				<DropdownToggle className="dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
					<span className="flag-icon flag-icon-fr m-r-5" title="fr"></span>
					<span className="name d-none d-sm-inline">FR</span>
				</DropdownToggle> : null
			}
			<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
				{
					language === "pt" ? [
						<DropdownItem key="en" onClick={() => changeLanguage('en')}><span className="flag-icon flag-icon-gb m-r-5" title="en"></span> EN</DropdownItem>,
						<DropdownItem key="fr" onClick={() => changeLanguage('fr')}><span className="flag-icon flag-icon-fr m-r-5" title="fr"></span> FR</DropdownItem>
					] : language === "en" ? [
						<DropdownItem key="pt" onClick={() => changeLanguage('pt')}><span className="flag-icon flag-icon-pt m-r-5" title="pt"></span> PT</DropdownItem>,
						<DropdownItem key="fr" onClick={() => changeLanguage('fr')}><span className="flag-icon flag-icon-fr m-r-5" title="fr"></span> FR</DropdownItem>
					] : language === "fr" ? [
						<DropdownItem key="pt" onClick={() => changeLanguage('pt')}><span className="flag-icon flag-icon-pt m-r-5" title="pt"></span> PT</DropdownItem>,
						<DropdownItem key="en" onClick={() => changeLanguage('en')}><span className="flag-icon flag-icon-gb m-r-5" title="en"></span> EN</DropdownItem>
					] : null
				}
					</DropdownMenu>
		</Dropdown>
		{(sweetAlertError &&
			<SweetAlert danger
					confirmBtnText="OK"
					confirmBtnBsStyle="default"
					title="Error"
					onConfirm={() => {logout();setSweetAlertError(false);}}
			>
				{errorMessage}
			</SweetAlert>
		)}
		</>
	);
};