import jwt_decode from 'jwt-decode';
import logout from '../../utils/logout';
import translations from '../../pages/translations.json';

const token = localStorage.token;

var Menu = [];

if(token) {
  try {
    const decode = jwt_decode(token);
    if (decode && decode.lenght !== 0) {
      if (decode.permissions[0]['permission_type'][0]['permission'][0].hasPermission) {
        Menu.push({ path: '/dashboard', icon: 'fa fa-th', title: translations[decode.language]['components']['sidebar']['menu']['dashboard'] });
      }
      if (decode.permissions[2]['permission_type'][0]['permission'][0].hasPermission) {
        Menu.push({ path: '/insts', icon: 'fa fa-building', title: translations[decode.language]['components']['sidebar']['menu']['inst'] });
      }
      if (decode.permissions[3]['permission_type'][0]['permission'][0].hasPermission) {
        Menu.push({ path: '/entity', icon: 'fa fa-search', title: translations[decode.language]['components']['sidebar']['menu']['entity'] });
      }
      if (decode.permissions[1]['permission_type'][0]['permission'][0].hasPermission || decode.permissions[1]['permission_type'][1]['permission'][0].hasPermission) {
        var children = [];
        if (decode.permissions[1]['permission_type'][0]['permission'][0].hasPermission) {
          children.push({ path: '/backoffice/users', icon:'fa fa-users text-theme', title: translations[decode.language]['components']['sidebar']['menu']['backoffice']['users'] });
        }
        if (decode.permissions[1]['permission_type'][1]['permission'][0].hasPermission) {
          children.push({ path: '/backoffice/user-types', icon:'fa fa-puzzle-piece text-theme', title: translations[decode.language]['components']['sidebar']['menu']['backoffice']['users-type'] });
        }
        Menu.push({ path: '/backoffice', icon: 'fa fa-desktop', title: 'Backoffice', children});
      }
    }
  } catch (err) {
    logout();
  }
  
}

export default Menu;