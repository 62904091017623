import React from 'react';

import Login from './../pages/Login';
import Dashboard from './../pages/Dashboard';
import Users from './../pages/Backoffice/Users';
import UserTypes from './../pages/Backoffice/UserTypes';
import Profile from './../pages/Profile';
import Insts from '../pages/Insts';
import Entity from '../pages/Entity';

import Pdfteste from './../pages/pdf/publish.js';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Login',
    component: () => <Login />
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    component: () => <Dashboard />
  },
  {
    path: '/backoffice/users',
    title: 'Users',
    component: () => <Users />
  },
  {
    path: '/insts',
    title: 'Insts',
    component: () => <Insts />
  },
  {
    path: '/entity',
    title: 'Entity',
    component: () => <Entity />
  },
  {
    path: '/backoffice/user-types',
    title: 'Users Type',
    component: () => <UserTypes />
  },
  {
    path: '/profile',
    title: 'Profile',
    component: () => <Profile />
  },
  {
    path: '/publish',
    title: 'publish',
    component: () => <Pdfteste />
  }
];


export default routes;