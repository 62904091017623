import axis from 'axios';
const https = require('https');
const api = axis.create({
  httpsAgent: new https.Agent({  
    rejectUnauthorized: false
  }),
  baseURL: 'https://cig.alentapp.pt:3333',
});

// const https = require('https');
// const api = axis.create({
//   httpsAgent: new https.Agent({
//     rejectUnauthorized: false
//   }),
//   baseURL: 'http://localhost:3333',
// });
export default api;