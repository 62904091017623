import React from 'react';
import { withRouter } from 'react-router-dom';
import { PageSettings } from './../../config/page-settings.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import md5 from 'md5';

import api from '../../services/api';
import validateEmail from '../../utils/validateEmail';

import checkSession from '../../utils/checkSession';

class Login extends React.Component {
	static contextType = PageSettings;

	constructor(props) {
    super(props);
    
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleIsMain = this.handleIsMain.bind(this);
		this.handleEntityChange = this.handleEntityChange.bind(this);
		this.handleDistrictChange = this.handleDistrictChange.bind(this);
		this.handleNewInst = this.handleNewInst.bind(this);
		
		let instCity = [];
		let instDistrict = [];
		let instOrig = [];
		let currentInstCity = [{id: 0, name: "Selecione o Distrito"}];
		(async () => {
			try {
				await api.get('/inst-district')
				.then(response => {
					let currentState = this.state;
					this.setState({
						...currentState,
						instDistrict: response.data
					})
				});
				await api.get('/inst-city')
				.then(response => {
					let currentState = this.state;
					this.setState({
						...currentState,
						instCity: response.data
					})
				});
				await api.get('/inst-orig')
				.then(response => {
					let currentState = this.state;
					this.setState({
						...currentState,
						instOrig: response.data
					})
				});
			} catch (err) {
				console.log(err)
			}
		})();

		var date = new Date();
		this.state = {
			email: '',
			password: '',
			sweetAlertError: false,
			errorMessage: '',
			sweetAlertSuccess: false,
			successMessage: '',
			isMain: true,
			idEntity: 0,
			instCity: instCity,
			instDistrict: instDistrict,
			instOrig: instOrig,
			currentInstCity: currentInstCity,
			year: date.getFullYear()
		}

  }
  
	componentDidMount() {
		this.context.handleSetPageSidebar(false);
		this.context.handleSetPageHeader(false);
		this.context.handleSetBodyWhiteBg(true);
	}

	componentWillUnmount() {
		this.context.handleSetPageSidebar(true);
		this.context.handleSetPageHeader(true);
		this.context.handleSetBodyWhiteBg(false);
	}
	
	async handleSubmit(event) {
		event.preventDefault();

		try {
			await api.post('/login', {
				email: this.state.email,
				password: md5(this.state.password)
			})
			.then(response => {
				localStorage.setItem('token', response.data);
				localStorage.setItem('language', "pt");

				console.log(response.data)

				var session = checkSession();
				// console.log('session', session)
				var route = session.permissions[0]['permission_type'][0]['route'];
				
				if (!session.permissions[0]['permission_type'][0]['permission'][0].hasPermission) {
					if (session.permissions[2]['permission_type'][0]['permission'][0].hasPermission) {
						route = session.permissions[2]['permission_type'][0]['route'];
					} else {
						if (session.permissions[3]['permission_type'][0]['permission'][0].hasPermission) {
							route = session.permissions[3]['permission_type'][0]['route'];
						} else {
							if (session.permissions[1]['permission_type'][0]['permission'][0].hasPermission) {
								route = session.permissions[1]['permission_type'][0]['route'];
							} else {

								if (session.permissions[1]['permission_type'][1]['permission'][0].hasPermission) {
									route = session.permissions[1]['permission_type'][1]['route'];
								} else {
									route = '/';
									
								}

							}
						}
					}
				}

				this.props.history.push(route);
				window.location.reload();
			})
			.catch(err => {
				this.setState({
					errorMessage: err.response.data.message,
					sweetAlertError: true
				});
				document.getElementById("formLogin").reset();
			})
		} catch (err) {
			alert(err.message);
		}
	  }
	  
	handleIsMain(){
		let currentState = this.state;
		this.setState({
			...currentState,
			isMain: !currentState.isMain
		});
		if(this.state.isMain === true){
			document.getElementById("formLogin").reset();
		}else{
			document.getElementById("formReq").reset();
		}		
	}
	handleEntityChange(e) {
		const idEntity = parseInt(e.target.value);
		let currentState = this.state;
		this.setState({
			...currentState,
			idEntity: idEntity
		});
		if(idEntity !== 1 && idEntity !== 2){
			document.getElementById('name').value = e.target.options[e.target.selectedIndex].text;
		}
	}
	handleDistrictChange(e) {
		const idDistrict = e.target.value;
		if(idDistrict !== "0"){
			let cities = [];
			cities[0] = {id: 0, name: "Selecione a Cidade."};
			this.state.instCity.map(city => parseInt(city.iddistrict) === parseInt(idDistrict) && (
				cities[cities.length] = city
			));
			let currentState = this.state;
			this.setState({
				...currentState,
				currentInstCity: cities
			})
		}else{
			let currentState = this.state;
			this.setState({
				...currentState,
				currentInstCity: [{id: 0, name: "Selecione o Distrito"}]
			})
		}
	}
	async handleNewInst(e) {
		e.preventDefault();
		var data = {};
		var val = 0;
		var msg = "";
		const ident = document.getElementById('idEntidade').value;
		if (ident && ident !== "0") {
			data.ident = ident;
		} else {
			val++;
			msg += "Selecione o Tipo de Instituição.";
		}

		const idcity = document.getElementById('idCity').value;
		if (idcity && idcity !== 0) {
			data.idcity = idcity;
		} else {
			val++;
			msg += "Selecione a Cidade.";
		}

		const name = document.getElementById('name').value;
		if (name && name !== '') {
			data.name = name;
		} else {
			val++;
			msg += "O campo do Nome da Instituição encontra-se vazio ou inválido.";
		}

		const tec = document.getElementById('tec').value;
		if (tec && tec !== '') {
			data.tec = tec;
		} else {
			val++;
			msg += "O campo do Nome do Técnico/a Responsável encontra-se vazio ou inválido.";
		}

		const email = document.getElementById('email').value;
		if (email && validateEmail(email)) {
			data.email = email;
		} else {
			val++;
			msg += "O campo do Email da Instituição encontra-se vazio ou inválido.";
		}

		const address = document.getElementById('address').value;
		if (address && address !== '') {
			data.address = address;
		} else {
			val++;
			msg += "O campo da Morada da Instituição encontra-se vazio ou inválido.";;
		}

		const postalcode = document.getElementById('postalcode').value;
		if (postalcode && postalcode !== '') {
			data.postalcode = postalcode;
		} else {
			val++;
			msg += "O campo do Código Postal da instituição encontra-se vazio ou inválido.";
		}

		const phone = document.getElementById('phone').value;
		if (phone && phone !== '') {
			data.phone = phone;
		} else {
			val++;
			msg += msg += "O campo do Contacto da Instituição encontra-se vazio ou inválido.";
		}
		if(parseInt(ident) === 3){
			const latitude = document.getElementById('latitude').value;
			if (latitude && latitude !== '') {
				data.latitude = latitude;
			} else {
				data.latitude = '';
			}
			
			const longitude = document.getElementById('longitude').value;
			if (longitude && longitude !== '') {
				data.longitude = longitude;
			} else {
				data.longitude = '';
			}

			const capacity_a = document.getElementById('capacity_a').value;
			if (capacity_a) {
				data.capacity_a = capacity_a;
			} else {
				val++;
				msg += "O campo do Capacidade Máxima de Abrigo (CA) encontra-se vazio ou inválido.";
			}

			const capacity_e = document.getElementById('capacity_e').value;
			if (capacity_e && capacity_e !== 0) {
				data.capacity_e = capacity_e;
			} else {
				val++;
				msg += "O campo do Capacidade Máxima de Emergência (CE) encontra-se vazio ou inválido.";
			}

			data.showinfo = 1;
		}else{
			data.capacity_a = 0;
			data.capacity_e = 0;
			data.showinfo = 1;
			data.latitude = '';
			data.longitude = '';
		}
		
		data.iduser = 1;
		data.userType = ident;

		if (val !== 0) {
			console.log("Erro:" + msg);
			this.setState({
				errorMessage: msg,
				sweetAlertError: true
			});
		} else {	
			try {
				await api.post('/inst-login', data)
				.then(response => {
					this.setState({
						successMessage: "Pré-registo efectuado com sucesso. Em caso de aprovação será contactado por email.",
						sweetAlertSuccess: true
					});
					this.handleIsMain();
				})
				.catch(err => {
					this.setState({
						errorMessage: err.message,
						sweetAlertError: true
					});
				})
			} catch (err) {
				this.setState({
					errorMessage: err.message,
					sweetAlertError: true
				});
			}
		}
	}
	
	render() {
		return (
			<div className="login login-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{backgroundImage: 'url(/assets/img/login-bg/login-bg-6.jpg)'}}></div>
					<div className="news-caption"><img src="/assets/img/logo/logobranco.png" alt="AA" style={{width:"25%"}} /></div>
				</div>
				<div className="right-content">
					<div className="login-header">
						<div className="brand">
						<center><img src="/assets/img/logo/logo-cig.png" alt="Logo" style={{height: "200px"}} />
							<small>Gestão de Vagas</small></center>
						</div>
						<div className="icon">
							<i className="fa fa-sign-in"></i>
						</div>
					</div>
					<div className="login-content">
						{this.state.isMain 
							? 
							<form id="formLogin" className="margin-bottom-0" onSubmit={this.handleSubmit}>
								<div className="form-group m-b-15">
									<input type="email" className="form-control form-control-lg" placeholder="Email" onChange={(e) => this.setState({ email: e.target.value })} required />
								</div>
								<div className="form-group m-b-15">
									<input type="password" className="form-control form-control-lg" placeholder="Password" onChange={(e) => this.setState({ password: e.target.value })} required />
								</div>
								<div className="m-b-30"></div>
								<div className="login-buttons">
									<button type="button" className="btn btn-info btn-lg" style={{width:"49%"}} onClick={() => this.handleIsMain()}>Pré-Registo</button>
									<button type="submit" className="btn btn-entity btn-lg" style={{width:"49%",marginLeft:"2%"}}>Login</button>
								</div>
							</form>
							:
							<form id="formReq" className="margin-bottom-0" onSubmit={this.handleNewInst}>
								<div className="form-group m-b-15">
									<select className="form-control" id="idEntidade" onChange={(e) => this.handleEntityChange(e)}>
										<option value={0}>Selecione o tipo de Instituição</option>
										{
											this.state.instOrig.map(orig => (
												<option key={orig.id} value={orig.id}>{orig.description}</option>
											))
										}
									</select>
								</div>
								
								<div className="form-group m-b-15">
									<input type="text" id="name" className="form-control" placeholder="Escreva o Nome da Instituição..." />
								</div>
								
								<div className="form-group m-b-15">
									<input type="text" id="tec" className="form-control" placeholder="Escreva o Nome Técnico/a Responsável..." />
								</div>

								<div className="form-group m-b-15">
									<input type="email" id="email" className="form-control" placeholder="Escreva o Email da Instituição..." />
								</div>

								<div className="form-group m-b-15">
									<input type="text" id="address" className="form-control" placeholder="Escreva a Morada da Instituição..." />
								</div>

								<div className="form-group m-b-15">
									<input type="text" id="postalcode" className="form-control" placeholder="Escreva o Código Postal da Instituição..." />
								</div>

								<div className="form-group m-b-15">
									<select className="form-control" id="idDistrict" onChange={(e) => this.handleDistrictChange(e)}>
										<option value={0}>Selecione o Distrito</option>
										{	
											this.state.instDistrict.map(district => (
												<option key={district.id} value={district.id}>{district.name}</option>
											))
										}
									</select>								
								</div>

								<div className="form-group m-b-15">
									<select className="form-control" id="idCity">
									{
										this.state.currentInstCity.map(currentInstCity => (
											<option key={currentInstCity.id} value={currentInstCity.id}>{currentInstCity.name}</option>
										))
									}
									</select>
								</div>
								<div className="form-group m-b-15">
									<input type="text" id="phone" className="form-control" placeholder="Escreva o Contacto da Instituição..." />
								</div>
								<div className="form-group m-b-15" style={(parseInt(this.state.idEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
									<input type="text" id="latitude" className="form-control" placeholder="Indique as coordenadas de latitude, se existirem..." />
								</div>
								<div className="form-group m-b-15" style={(parseInt(this.state.idEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
									<input type="text" id="longitude" className="form-control" placeholder="Indique as coordenadas de longitude, se existirem..." />
								</div>
								<div className="form-group m-b-15" style={(parseInt(this.state.idEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
									<input type="number" id="capacity_a" className="form-control" placeholder="Indique a Capacidade Máxima Casa Abrigo (CA) da Instituição, se existir..." />
								</div>
								<div className="form-group m-b-15" style={(parseInt(this.state.idEntity) !== 1) ? { display: 'none' } : { display: 'flex' }}>
									<input type="number" id="capacity_e" className="form-control" placeholder="Indique a Capacidade Máxima Casa Emergência (CE) da Instituição, se existir..." />
								</div>
								<div className="m-b-30"></div>
								<div className="login-buttons">
									<button className="btn btn-info btn-lg" style={{width:"49%"}} onClick={() => this.handleIsMain()}>Voltar</button>
									<button type="submit" className="btn btn-entity btn-lg" style={{width:"49%",marginLeft:"2%"}}>Pré-registar</button>
								</div>
							</form>
						}
						<hr />
						<p className="text-center text-grey-darker">
							&copy; alentapp ® All Rights Reserved {this.state.year}
						</p>
						<center>
							<img src="/assets/img/logo/logo_republica.png" alt="AA" style={{width:"40%",marginTop:"16px"}} />
							<img src="/assets/img/logo/logo_comb.jpg" alt="AA" style={{width:"100%",marginTop:"22px"}} />
						</center>
					</div>
				</div>
				{(this.state.sweetAlertError &&
					<SweetAlert danger
							confirmBtnText="OK"
							confirmBtnBsStyle="default"
							title="Error"
							onConfirm={() => this.setState({ sweetAlertError: false })}
					>
						{this.state.errorMessage}
					</SweetAlert>
				)}
				{(this.state.sweetAlertSuccess &&
					<SweetAlert success
							confirmBtnText="OK"
							confirmBtnBsStyle="default"
							title="Sucesso"
							onConfirm={() => this.setState({ sweetAlertSuccess: false })}
					>
						{this.state.successMessage}
					</SweetAlert>
				)}
			</div>
		)
	}
}

export default withRouter(Login);