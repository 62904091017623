import jwt_decode from 'jwt-decode';
import logout from './logout';

export default function checkSession() {
  var session;
	try {
		session = jwt_decode(localStorage.token);
	} catch (err) {
		logout();
  }
  
  return session;
}