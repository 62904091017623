import React from 'react';
import { PDFViewer, Document, Page, View, Text } from '@react-pdf/renderer'

export default class Publish extends React.Component {
  constructor(props) {
    super(props);

    this.setStateFromLink = this.setStateFromLink.bind(this);
    
    this.state = {
      ready: true,
      foo: 'Unchanged Value',
    };
  }
  setStateFromLink(state) { // set state from incoming <Link>
    if(state) {
      const { foo } = state 
      this.setState({ foo })
    }    
  }

  render() {
    if (this.state.ready) {
      
      return (
        <React.Fragment>
          <PDFViewer>
            <Document>
              <Page>
                <View>
                  <Text>Foo: {this.state.foo}</Text>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </React.Fragment>
      )
    } else {
      return null
    }
  }
}

