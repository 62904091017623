import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import api from '../../../services/api';
import translations from './../../translations.json';
import checkSession from '../../../utils/checkSession';

export default function UsersType() {
	var session = checkSession();
	
	if (!session.permissions[1]['permission_type'][1]['permission'][0].hasPermission) {
		window.location.href = '/dashboard';
	}

	const [modalDialog, setModalDialog] = useState(false);

	const [usersTypeState, setUsersTypeState] = useState(true);
	const [permissionState, setPermissionState] = useState(false);

	const [sweetAlertSuccess, setSweetAlertSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const [sweetAlertWarning, setSweetAlertWarning] = useState(false);

	const [sweetAlertError, setSweetAlertError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	async function toggleModal(id) {
		await setModalDialog(!modalDialog);
		if (id !== 0) {
			const userType = usersType.find(x => x.id === id);
			
			setId(id);
			document.getElementById('description').value = userType.description;
		}else{
			setId(0);
			document.getElementById('description').value = '';
		}
		
	}

	const [expand, setExpand] = useState(false);
	const [reload, setReload] = useState(false);
	const [reloadPermissions, setReloadPermissions] = useState(false);

	function toggleExpand() {
		setExpand(!expand);
	}

	const defaultSorted = [
		{
			id: "id",
			desc: false
		}
	];

	const tableColumns = [
		{
			Header: "#",
			accessor: "id",
			width: 50,
		},
		{
			Header: translations[session.language]['backoffice']['users-type']['users-type']['panel']['body']['table'].description,
			accessor: "description"
		},
		{
			id: 'button',
			Header: translations[session.language]['backoffice']['users-type']['users-type']['panel']['body']['table'].options,
			accessor: 'id',
			width: 300,
			Cell: ({value}) => (
				<center>
					{(session.permissions[1]['permission_type'][1]['permission'][3].hasPermission
					? <button className='btn btn-warning btn-sm' onClick={() => loadPermissions(value)}>
							<i className="fa fa-puzzle-piece"></i>&nbsp;{translations[session.language]['backoffice']['users-type']['users-type']['panel']['body']['table']['buttons'].permissions}
						</button>
					: '')}
					{(session.permissions[1]['permission_type'][1]['permission'][2].hasPermission
					? <button className='btn btn-primary btn-sm ml-1' onClick={() => toggleModal(value)}>
							<i className="fa fa-pencil-alt"></i>&nbsp;{translations[session.language]['backoffice']['users-type']['users-type']['panel']['body']['table']['buttons'].edit}
						</button>
					: '')}
					{(session.permissions[1]['permission_type'][1]['permission'][4].hasPermission
					? <button className='btn btn-danger btn-sm ml-1' onClick={() => {setDeleteUserType(value);setSweetAlertWarning(true)}}>
							<i className="fas fa-lg fa-trash"></i>&nbsp;{translations[session.language]['backoffice']['users-type']['users-type']['panel']['body']['table']['buttons'].delete}
						</button>
					: '')}
				</center>
			)
		}
	];

	const [usersType, setUsersType] = useState([]);

	useEffect(() => {
		loadData();
	}, []);

	async function loadData() {
		setReload(true);
		try {
			await api.get('/users-type')
			.then(response => {
				setUsersType(response.data);
				setPermissions([]);
				setPermissionState(false);
				setUsersTypeState(true);
			});
			setReload(false);
		} catch (err) {
			alert(err.message);
		}
	}

	const [id, setId] = useState(0);

	async function handleNewUserType(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";

		const description = document.getElementById('description').value;
		if (description && description !== '') {
			data.description = description;
		} else {
			val++;
			msg += "O campo da Descrição encontra-se vazio ou inválido.";
		}

		if (val !== 0) {
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {
			try {
				await api.post('/users-type', data)
				.then(response => {
					setModalDialog(false);
					setSuccessMessage(translations[session.language]['backoffice']['users-type']['users-type']['responses']['create'][response.data]);
					setSweetAlertSuccess(true);
					loadData();
				})
				.catch(err => {
					setErrorMessage(err.response.data.message);
					setSweetAlertError(true);
				})
			} catch (err) {
				alert(err.message);
			}
		}
	}

	async function handleEditUserType(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";

		const description = document.getElementById('description').value;
		if (description && description !== '') {
			data.description = description;
		} else {
			val++;
			msg += "O campo da Descrição encontra-se vazio ou inválido.";
		}

		if (val !== 0) {
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {
			try {
				await api.put(`/users-type/${id}`, data)
				.then(response => {
					setModalDialog(false);
					setSuccessMessage(translations[session.language]['backoffice']['users-type']['users-type']['responses']['edit'][response.data]);
					setSweetAlertSuccess(true);
					loadData();
				})
				.catch(err => {
					setErrorMessage(err.response.data.message);
					setSweetAlertError(true);
				})
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}

	const [deleteUserType, setDeleteUserType] = useState(0);
	async function handleDeleteUserType(id) {
		try {
			await api.delete(`/users-type/${id}`)
			.then(response => {
				setSuccessMessage(translations[session.language]['backoffice']['users-type']['users-type']['responses']['delete'][response.data]);
				setSweetAlertSuccess(true);
				loadData();
			})
			.catch(err => {
				setErrorMessage(err.response.data.message);
				setSweetAlertError(true);
			})
		} catch (err) {
			alert(err.message);
		}
	}
	
	function toggleCollapse(index) {
		var newArray = [];
		for (let collapseObj of permissions) {
			if (collapseObj.id === index) {
				collapseObj.collapse = !collapseObj.collapse;
			} else {
				collapseObj.collapse = false;
			}
			newArray.push(collapseObj);
		}

		setPermissions(newArray);
	}

	const [permissions, setPermissions] = useState([]);
	async function loadPermissions(id) {
		setReloadPermissions(true);
		try {
			await api.get(`users-type-permissions/${id}`)
			.then(response => {
				response.data.map((item, i) => item.collapse = i === 0 ? true : false);
				setPermissions(response.data);
				setId(id);
				setUsersTypeState(false);
				setPermissionState(true);
			});
			setReloadPermissions(false);
		} catch (err) {
			alert(err.message);
		}
	}

	function handleChange(i, j, k) {
		let tmp = permissions;
		tmp[i]['permission_type'][j]['permission'][k]['hasPermission'] = !tmp[i]['permission_type'][j]['permission'][k]['hasPermission'];
		setPermissions(tmp);
	}

	async function handleSavePermissions() {
		try {
			await api.post(`/users-type-permissions/${id}`, permissions)
			.then(response => {
				setSuccessMessage(translations[session.language]['backoffice']['users-type']['permissions']['responses']['save'][response.data]);
				setSweetAlertSuccess(true);
				setId(0);
				loadData();
			})
			.catch(err => {
				setErrorMessage(err.response.data.message);
				setSweetAlertError(true);
			})
		} catch (err) {
			alert(err.message);
		}
	}
	
	return (
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
				<li className="breadcrumb-item active">Backoffice</li>
				<li className="breadcrumb-item active">{translations[session.language]['backoffice']['users-type']['users-type'].title}</li>
			</ol>
			<h1 className="page-header">{translations[session.language]['backoffice']['users-type']['users-type'].title}</h1>
			<div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')} style={{display: usersTypeState ? 'block' : 'none'}}>
				<div className="panel-heading">
					<h4 className="panel-title">{translations[session.language]['backoffice']['users-type']['users-type']['panel'].title}</h4>
						<div className="panel-heading-btn">
							{(session.permissions[1]['permission_type'][1]['permission'][1].hasPermission
							? <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => toggleModal(0)}><i className="fa fa-plus"></i></button>
							: '')}
							&nbsp;&nbsp;<button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => loadData()}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
						</div>
				</div>
				<div className="panel-body">
					<ReactTable data={usersType} columns={tableColumns} defaultPageSize={10} defaultSorted={defaultSorted} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
					{(reload && 
						<div className="panel-loader">
							<span className="spinner-small"></span>
						</div>
					)}
					</div>
			</div>

			<div className={'panel panel-inverse ' + (reload ? 'panel-loading ' : ' ')} style={{display: permissionState ? 'block' : 'none'}}>
				<div className="panel-heading">
					<h4 className="panel-title">{translations[session.language]['backoffice']['users-type']['permissions']['panel'].title}</h4>
						<div className="panel-heading-btn">
							<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={loadData}><i className="fa fa-reply"></i></button>&nbsp;&nbsp;
						</div>
				</div>
				<div className="panel-body">
					<div className="row">
						<div className="col-md-12">
							<button type="button" className="btn btn-primary pull-right" onClick={() => handleSavePermissions()}>{translations[session.language]['backoffice']['users-type']['permissions']['panel']['buttons'].save}</button>
						</div>
					</div>
					<br/>
					<div className="row">
						<div className="col-md-12">
							<div id="accordion" className="accordion">
								{
									permissions.map((pages, i) => (
									<Card className='card' key={i}>
										<CardHeader className={'card-header bg-black text-white pointer-cursor ' + (!pages.collapse ? 'collapsed ' : '')} onClick={() => toggleCollapse(pages.id)} style={{cursor: 'pointer'}}>
											<i className="fa fa-circle f-s-8 mr-2" style={{ color: "#1c75c7"}}></i> {pages.description}
										</CardHeader>
										<Collapse isOpen={pages.collapse}>
											<CardBody>
												{
													permissions[i]['permission_type'].map((permission_type, j) => (
														<div key={j} style={j !== 0 ? {marginTop: "2%"} : {}}>
															<h5>{permission_type.description}</h5>
															<div className="row">
																{
																	permissions[i]['permission_type'][j]['permission'].map((permission, k) => (
																		<div className="col-md-6" key={k}>
																			<div className="checkbox checkbox-css">
																				<input type="checkbox" id={'cssCheckbox' + permission.id} onClick={() => handleChange(i, j, k)} defaultChecked={permission.hasPermission} />
																				<label htmlFor={'cssCheckbox' + permission.id}>{permission.notes}</label>
																			</div>
																		</div>
																	))
																}
															</div>
														</div>
													))
												}
											</CardBody>
										</Collapse>
									</Card>
									))
								}
							</div>
						</div>
					</div>
					{(reloadPermissions && 
						<div className="panel-loader">
							<span className="spinner-small"></span>
						</div>
					)}
				</div>
			</div>

			<Modal isOpen={modalDialog} toggle={() => toggleModal(0)}>
				<ModalHeader toggle={() => toggleModal(0)}>
					{
						id === 0 ? 
						translations[session.language]['backoffice']['users-type']['users-type']['modal']['header'].add : 
						translations[session.language]['backoffice']['users-type']['users-type']['modal']['header'].edit
					}
				</ModalHeader>
				<form onSubmit={id === 0 ? handleNewUserType : handleEditUserType}>
					<ModalBody>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users-type']['users-type']['modal']['body']['description'].title}</label>
							<div className="col-md-9">
								<input type="text" id="description" className="form-control" placeholder={translations[session.language]['backoffice']['users-type']['users-type']['modal']['body']['description'].placeholder} />
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations[session.language]['backoffice']['users-type']['users-type']['modal']['footer'].close}</button>
						<button type="submit" className="btn btn-primary">{translations[session.language]['backoffice']['users-type']['users-type']['modal']['footer'].save}</button>
					</ModalFooter>
				</form>
			</Modal>
			{(sweetAlertSuccess &&
				<SweetAlert success
					confirmBtnText="OK"
					confirmBtnBsStyle="success"
					title={translations[session.language]['alerts']['success'].title}
					onConfirm={() => setSweetAlertSuccess(false)}
				>
					{successMessage}
				</SweetAlert>
			)}
			{(sweetAlertWarning &&
					<SweetAlert warning showCancel
						confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
						cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="default"
						title={translations[session.language]['alerts']['warning'].title}
						onConfirm={() => {handleDeleteUserType(deleteUserType);setSweetAlertWarning(false)}}
						onCancel={() => setSweetAlertWarning(false)}
					>
						{translations[session.language]['backoffice']['users-type']['users-type']['alerts']['warning'].message}
					</SweetAlert>
				)}
			{(sweetAlertError &&
					<SweetAlert danger
							confirmBtnText="OK"
							confirmBtnBsStyle="default"
							title={translations[session.language]['alerts']['error'].title}
							onConfirm={() => setSweetAlertError(false)}
					>
						{errorMessage}
					</SweetAlert>
			)}
		</div>
	)
}