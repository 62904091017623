import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import jwt_decode from 'jwt-decode';

import config from '../../../services/config';
import translations from '../../../pages/translations.json';

class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false,
			name: null,
			photo: null,
		};

		this.handleLogout = this.handleLogout.bind(this);
	}

	componentDidMount() {
		try {
			const decode = jwt_decode(localStorage.token);
			this.setState({
				name: decode.name,
				photo: decode.photo,
				language: decode.language
			});
		} catch (err) {
			//err
		}
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	handleLogout() {
		localStorage.removeItem('token');
		this.props.history.push('/');
	}
  
	render() {
		const { name, photo, language = 'pt' } = this.state;
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
				<DropdownToggle tag="a" style={{cursor: "pointer"}}>
					<img src={config.base_url+photo} alt="Profile" /> 
					<span className="d-none d-md-inline">{name}</span> <b className="caret"></b>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
					<DropdownItem href="/profile">{translations[language]['components']['header']['edit-profile']}</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem onClick={this.handleLogout}>Logout</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default withRouter(DropdownProfile);
