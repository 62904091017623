import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import api from '../../../services/api';
import translations from '../../translations.json';
import checkSession from '../../../utils/checkSession';
import validateEmail from '../../../utils/validateEmail';
import validateImage from '../../../utils/validateImage';
import logout from '../../../utils/logout';
import md5 from 'md5';
//import Insts from '../../Entity';

export default function Users() {
	var session = checkSession();

	if (!session.permissions[2]['permission_type'][0]['permission'][0].hasPermission) {
		window.location.href = '/dashboard';
	}

	const [modalDialog, setModalDialog] = useState(false);

	const [sweetAlertSuccess, setSweetAlertSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const [sweetAlertWarning, setSweetAlertWarning] = useState(false);

	const [sweetAlertError, setSweetAlertError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	async function toggleModal(id) {
		await setModalDialog(!modalDialog);
		if (id !== 0) {
			const user = users.find(x => x.id === id);
			document.getElementById('name').value = user.name;
			document.getElementById('email').value = user.email;
			document.getElementById('userType').value = user.id_core_user_type;
			document.getElementById('phone').value = user.phone;
			if(user.id_core_user_type === 3 || user.id_core_user_type === 4){
				let arrinsts = [];
				let inst_type = 0;
				if(user.id_core_user_type === 3){
					inst_type = 1;
				}else if(user.id_core_user_type === 4){
					inst_type = 2;
				}
				for(let i = 0;i < insts.length;i++){
					if(insts[i].ident === inst_type){
						arrinsts[arrinsts.length] = insts[i]; 
					}
				}
				setIsInst(user.id_core_user_type);
				setCurrentInsts(arrinsts);
				setTimeout(function(){ document.getElementById('idInst').value = user.idinst; },1000)
			}else{
				setIsInst(0);
			}	
			setId(id);
			
		}else{
			setIsInst(0);
			setId(0);
		}
	}

	const [expand, setExpand] = useState(false);
	const [reload, setReload] = useState(false);

	function toggleExpand() {
		setExpand(!expand);
	}

	const defaultSorted = [
		{
			id: "name",
			desc: false
		}
	];
	const tableColumns = [
		{
			Header: translations[session.language]['backoffice']['users']['panel']['body']['table'].name,
			accessor: "name"
		},
		{
			Header: translations[session.language]['backoffice']['users']['panel']['body']['table'].email,
			accessor: "email"
		},
		{
			Header: translations[session.language]['backoffice']['users']['panel']['body']['table']['user-type'],
			accessor: "description",
			width: 200,
		},
		{
			Header: "Estado",
			accessor: "estado"
		},
		{
			id: 'button',
			Header: translations[session.language]['backoffice']['users']['panel']['body']['table'].options,
			accessor: 'id',
			width: 200,
			Cell: ({value}) => (
				<center>
					{(session.permissions[2]['permission_type'][0]['permission'][2].hasPermission 
					? <button className='btn btn-warning btn-sm' onClick={() => toggleModal(value)} title={translations[session.language]['backoffice']['users']['panel']['body']['table']['buttons'].edit}>
							<i className="fa fa-pencil-alt"></i>
						</button>
					: '')}
					{(session.permissions[2]['permission_type'][0]['permission'][3].hasPermission 
					? <button className='btn btn-danger btn-sm ml-1' onClick={() => {setDeleteUser(value);setSweetAlertWarning(true)}} title={translations[session.language]['backoffice']['users']['panel']['body']['table']['buttons'].delete}>
						<i className="fas fa-lg fa-trash"></i>
					</button>
					: '')}
				</center>
			)
		}
	];

	const [users, setUsers] = useState([]);
	const [usersFiltered, setUsersFiltered] = useState([]);
	const [usersType, setUsersType] = useState([]);
	
	const [insts, setInsts] = useState([]);
	const [currentInsts, setCurrentInsts] = useState([]);

	useEffect(() => {
		loadData();
		(async () => {
			try {
				await api.get('/users-type')
				.then(response => {
					setUsersType(response.data);
				});
				await api.get('/inst')
				.then(response => {
					setInsts(response.data);
				});
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		})();
	}, []);

	async function loadData() {
		setReload(true);
		try {
			await api.get('/users')
			.then(response => {
				setUsers(response.data);
				setUsersFiltered(response.data);
			});
			await api.get('/users-type')
			.then(response => {
				setUsersType(response.data);
			});
			setReload(false);
			let pesquisa = document.getElementById('search').value;
			let filter;
			if(pesquisa){
				let results = [];
				if (pesquisa.indexOf(' ') !== -1) {
					filter = pesquisa.split(' ');
					filter.indexOf("") !== -1 && filter.splice(filter.indexOf(""),1);
					for(let a = 0;a < users.length;a++){
						for(let b = 0;b < filter.length;b++){
							if(users[a].name.toLowerCase().includes(filter[b]) || users[a].email.toLowerCase().includes(filter[b]) || users[a].description.toLowerCase().includes(filter[b])){
								results[results.length] = users[a];
								break;
							}
						}
					}
				}else{
					for(let a = 0;a < users.length;a++){
						if(users[a].name.toLowerCase().includes(pesquisa) || users[a].email.toLowerCase().includes(pesquisa) || users[a].description.toLowerCase().includes(pesquisa)){
							results[results.length] = users[a];
						}
					}
				}
				setUsersFiltered(results);
			}
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}

	const [id, setId] = useState(0);

	async function handleNewUser(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";
		const name = document.getElementById('name').value;
		if (name && name !== '') {
			data.name = name;
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['name'] + "<br />";
		}

		const email = document.getElementById('email').value;
		if (email && validateEmail(email)) {
			let checkMail = 0;
			for (var i = 0; i < users.length; i++) {
				if(users[i].email === email){
					checkMail = 1;
					break;
				}
			}
			if(checkMail === 0){
				data.email = email;
			}else{
				val++;
				msg += translations[session.language]['backoffice']['users']['modal']['validations']['emailExists'] + "<br />";
			}
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['email'] + "<br />";
		}

		const password = document.getElementById('password').value;
		if (password && password !== '') {
			data.password = md5(password);
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['password'] + "<br />";
		}

		const passwordConfirmation = document.getElementById('passwordConfirmation').value;
		if (passwordConfirmation && passwordConfirmation !== '') {
			data.passwordConfirmation = md5(passwordConfirmation);
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['password-confirmation'] + "<br />";
		}

		const userType = document.getElementById('userType').value;
		if (userType && userType !== "0") {
			data.userType = userType;
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['user-type'] + "<br />";
		}
		const phone = document.getElementById('phone').value;
		if (phone && phone !== '') {
			data.phone = phone;
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['phone'] + "<br />";
		}
		if(parseInt(data.userType) === 3 || parseInt(data.userType) === 4){
			data.ident = 0;
			const idinst = document.getElementById('idInst').value;
			if (idinst && idinst !== 0) {
				data.idinst = idinst;
			} else {
				val++;
				msg += translations[session.language]['backoffice']['users']['modal']['validations']['city'] + "<br />";
			}
			data.iduser = session.id
		}

		if (val !== 0) {
			setErrorMessage(msg.split("<br />").join("\n"));
			setSweetAlertError(true);
		} else {
			if (password === passwordConfirmation) {
				let destination = '/users';
				try {
					await api.post(destination, data)
					.then(response => {
						if(document.getElementById("file").files.length !== 0) {
							handleUploadImage();
						}
						setModalDialog(false);
						setSuccessMessage(translations[session.language]['backoffice']['users']['responses']['create'][response.data]);
						setSweetAlertSuccess(true);
						loadData();
					})
					.catch(err => {
						setErrorMessage(translations[session.language]['backoffice']['users']['responses']['create'][err.response.data]);
						setSweetAlertError(true);
					})
				} catch (err) {
					setErrorMessage(err.message);
					setSweetAlertError(true);
				}
			}else{
				setErrorMessage(translations[session.language]['backoffice']['users']['responses']['edit']['error-password-match'])
				setSweetAlertError(true);
			}
		}
	}

	async function handleEditUser(e) {
		e.preventDefault();

		var data = {};
		var val = 0;
		var msg = "";
		const name = document.getElementById('name').value;
		if (name && name !== '') {
			data.name = name;
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['name'];
		}

		const email = document.getElementById('email').value;
		if (email && validateEmail(email)) {
			let checkMail = 0;
			for (var i = 0; i < users.length; i++) {
				if(users[i].email === email && users[i].id !== id){
					checkMail = 1;
					break;
				}
			}
			if(checkMail === 0){
				data.email = email;
			}else{
				val++;
				msg += translations[session.language]['backoffice']['users']['modal']['validations']['emailExists'] + "<br />";
			}
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['email'];
		}

		const userType = document.getElementById('userType').value;
		if (userType && userType !== "0") {
			data.userType = userType;
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['user-type'];
		}
		const phone = document.getElementById('phone').value;
		if (phone && phone !== '') {
			data.phone = phone;
		} else {
			val++;
			msg += translations[session.language]['backoffice']['users']['modal']['validations']['phone'] + "<br />";
		}
		if(parseInt(data.userType) === 3 || parseInt(data.userType) === 4){
			const idinst = document.getElementById('idInst').value;
			if (idinst && idinst !== 0) {
				data.idinst = idinst;
			} else {
				val++;
				msg += translations[session.language]['backoffice']['users']['modal']['validations']['city'];
			}
			data.iduser = session.id
		}

		const password = document.getElementById('password').value;
		const passwordConfirmation = document.getElementById('passwordConfirmation').value;
		if (password !== '' || passwordConfirmation !== '') {
			if (password && password !== '') {
				data.password = md5(password);
			} else {
				val++;
				msg += translations[session.language]['backoffice']['users']['modal']['validations']['password'];
			}
			if (passwordConfirmation && passwordConfirmation !== '') {
				data.passwordConfirmation = md5(passwordConfirmation);
			} else {
				val++;
				msg += translations[session.language]['backoffice']['users']['modal']['validations']['password-confirmation'];
			}
		} else {
			data.password = null;
			data.passwordConfirmation = null;
		}

		if (val !== 0) {
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {
			if (password === passwordConfirmation) {
				let destination = `/users/${id}`;
				try {
					await api.put(destination, data)
					.then(response => {
						if(document.getElementById("file").files.length !== 0) {
							handleUploadImage();
						}
						setModalDialog(false);
						setSuccessMessage(translations[session.language]['backoffice']['users']['responses']['edit'][response.data]);
						setSweetAlertSuccess(true);
						loadData();
					})
					.catch(err => {
						setErrorMessage(translations[session.language]['backoffice']['users']['responses']['edit'][err.response.data]);
						setSweetAlertError(true);
					})
				} catch (err) {
					setErrorMessage(err.message);
					setSweetAlertError(true);
				}
			} else{
				setErrorMessage(translations[session.language]['backoffice']['users']['responses']['edit']['error-password-match'])
				setSweetAlertError(true);
			}
		}
	}

	const [deleteUser, setDeleteUser] = useState(0);
	async function handleDeleteUser(id) {
		try {
			await api.delete(`/users/${id}`)
			.then(response => {
				setSuccessMessage(translations[session.language]['backoffice']['users']['responses']['delete'][response.data]);
				setSweetAlertSuccess(true);
				loadData();
			})
			.catch(err => {
				setErrorMessage(translations[session.language]['backoffice']['users']['responses']['delete'][err.response.data]);
				setSweetAlertError(true);
			})
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}

	async function handleUploadImage() {
		const data = new FormData();
		data.append('file', document.getElementById('file').files[0]);

		try {
			await api.put(`/profile-image/${id}`, data, {
				headers: {
					token: localStorage.token
				}
			})
			.then(response => {
				localStorage.setItem('token', response.data.token);
				document.getElementById('file').value = '';
			})
			.catch(err => {
				if (err.response.status === 401) {
					setErrorMessage(translations['pt']['alert']['session'].error);
					setTimeout(() => {
						logout();
					}, 2000);
				} else {
					setErrorMessage(err.response.data.message);
				}
				setSweetAlertError(true);
			})
		} catch (err) {
			alert(err.message);
		}
  	}
	const [isInst, setIsInst] = useState(0);
	function handleTypeChange(e) {
		const idType = e.target.value;
		if(parseInt(idType) === 3 || parseInt(idType) === 4){
			setIsInst(idType);	
			let arrinsts = [];
			for(let i = 0;i < insts.length;i++){
				if(parseInt(idType) === 3){
					if(insts[i].ident === 1){
						arrinsts[arrinsts.length] = insts[i]; 
					}
				}else{
					if(insts[i].ident !== 1){
						arrinsts[arrinsts.length] = insts[i]; 
					}
				}
				
			}
			setCurrentInsts(arrinsts);
		}else{
			setIsInst(0);
			setCurrentInsts([]);
		}
	}
	function handleFilter(e){
		const pesquisa = e.target.value.toLowerCase();
		let filter;
		if(pesquisa === ''){
			setUsersFiltered(users);
		}else{
			let results = [];
			if (pesquisa.indexOf(' ') !== -1) {
				filter = pesquisa.split(' ');
				filter.indexOf("") !== -1 && filter.splice(filter.indexOf(""),1);
				for(let a = 0;a < users.length;a++){
					for(let b = 0;b < filter.length;b++){
						if(users[a].name.toLowerCase().includes(filter[b]) || users[a].email.toLowerCase().includes(filter[b]) || users[a].description.toLowerCase().includes(filter[b])){
							results[results.length] = users[a];
							break;
						}
					}
				}
			}else{
				for(let a = 0;a < users.length;a++){
					if(users[a].name.toLowerCase().includes(pesquisa) || users[a].email.toLowerCase().includes(pesquisa) || users[a].description.toLowerCase().includes(pesquisa)){
						results[results.length] = users[a];
					}
				}
			}
			setUsersFiltered(results);
		}
	}
	return (
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
				<li className="breadcrumb-item active">Backoffice</li>
				<li className="breadcrumb-item active">{translations[session.language]['backoffice']['users'].title}</li>
			</ol>
			<h1 className="page-header">{translations[session.language]['backoffice']['users'].title}</h1>

			<div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
				<div className="panel-heading">
					<h4 className="panel-title">{translations[session.language]['backoffice']['users']['panel'].title}</h4>
						<div className="panel-heading-btn">
							{(session.permissions[2]['permission_type'][0]['permission'][1].hasPermission
							? <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={() => toggleModal(0)}><i className="fa fa-plus"></i></button>
							: '')}
							&nbsp;&nbsp;<button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => loadData()}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
						</div>
				</div>
				<div className="panel-body row">
					<div className='col-md-12'>
						<div className="form-group row m-b-15 pull-right">
							<label className="col-form-label col-md-3">Procurar:</label>
							<div className="col-md-9">
								<input type="text" id="search" className="form-control" onChange={(e) => handleFilter(e)}/>
							</div>
						</div>
					</div>
					<div className='col-md-12'>
						<ReactTable data={usersFiltered} columns={tableColumns} defaultPageSize={10} defaultSorted={defaultSorted} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
						{(reload && 
							<div className="panel-loader">
								<span className="spinner-small"></span>
							</div>
						)}
					</div>
				</div>
			</div>

			<Modal isOpen={modalDialog} toggle={() => toggleModal(0)}>
				<ModalHeader toggle={() => toggleModal(0)}>
					{
						id === 0 ? 
						translations[session.language]['backoffice']['users']['modal']['header']['add'] : 
						translations[session.language]['backoffice']['users']['modal']['header']['edit']
					}
				</ModalHeader>
				<form onSubmit={id === 0 ? handleNewUser : handleEditUser}>
					<ModalBody>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['name'].title}</label>
							<div className="col-md-9">
								<input type="text" id="name" className="form-control" placeholder={translations[session.language]['backoffice']['users']['modal']['body']['name'].placeholder} />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['email'].title}</label>
							<div className="col-md-9">
								<input type="email" id="email" className="form-control" placeholder={translations[session.language]['backoffice']['users']['modal']['body']['email'].placeholder} />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['phone'].title}</label>
							<div className="col-md-9">
								<input type="text" id="phone" className="form-control" placeholder={translations[session.language]['backoffice']['users']['modal']['body']['phone'].placeholder} />
							</div>
						</div>	
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['user-type'].title}</label>
							<div className="col-md-9">
								<select className="form-control" id="userType" onChange={(e) => handleTypeChange(e)}>
									<option value={0}>{translations[session.language]['backoffice']['users']['modal']['body']['user-type'].placeholder}</option>
									{
										usersType.map(userType => (
											<option key={userType.id} value={userType.id}>{userType.description}</option>
										))
									}
								</select>
							</div>
						</div>
						<div className="form-group row m-b-15" style={id === 0 ? { display: 'none' } : { display: 'flex' }}>
							<div className="col-md-12">
								<button type="button" id="btnPassword" className="btn btn-warning pull-right" onClick={() => {
									document.getElementById('btnPassword').style.display = 'none';
									document.getElementById('passwordDiv').style.display = 'block';
								}}>Alterar Password</button>
							</div>
						</div>
						<div id="passwordDiv" style={id === 0 ? { display: 'block' } : { display: 'none' }}>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['password'].title}</label>
								<div className="col-md-9">
									<input type="password" id="password" className="form-control" placeholder={translations[session.language]['backoffice']['users']['modal']['body']['password'].placeholder} />
								</div>
							</div>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['password-confirmation'].title}</label>
								<div className="col-md-9">
									<input type="password" id="passwordConfirmation" className="form-control" placeholder={translations[session.language]['backoffice']['users']['modal']['body']['password-confirmation'].placeholder} />
								</div>
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['photo'].title}</label>
							<div className="col-md-9">
								<input type="file" className="form-control" id="file"
									onChange={(e) => {
										const image = e.target.files[0];
										
										if (validateImage(image)) {
											if (image.size > (3 * 1024 * 1024)) {
												setErrorMessage(translations[session.language]['backoffice']['users']['modal']['body']['photo']['size']);
												setSweetAlertError(true);
											}
										} else {
											document.getElementById('file').value = null;
											setErrorMessage(translations[session.language]['utils']['validateImage']['extension']);
											setSweetAlertError(true);
										}
									}}
								/>
							</div>
						</div>
						{parseInt(isInst) !== 0 
							?
								<div className="form-group row m-b-15">
									<label className="col-form-label col-md-3">{translations[session.language]['backoffice']['users']['modal']['body']['inst'].title}</label>
									<div className="col-md-9">
										<select className="form-control" id="idInst">
											<option value={0}>{translations[session.language]['backoffice']['users']['modal']['body']['inst'].placeholder}</option>
											{
												currentInsts.map(currentInst => (
													<option key={currentInst.id} value={currentInst.id}>{currentInst.name}</option>
												))
											}
										</select>
									</div>
								</div>
							: ''
						}
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations[session.language]['backoffice']['users']['modal']['footer'].close}</button>
						<button type="submit" className="btn btn-primary">{translations[session.language]['backoffice']['users']['modal']['footer'].save}</button>
					</ModalFooter>
				</form>
			</Modal>
			{(sweetAlertSuccess &&
				<SweetAlert success
					confirmBtnText="OK"
					confirmBtnBsStyle="success"
					title={translations[session.language]['alerts']['success'].title}
					onConfirm={() => setSweetAlertSuccess(false)}
				>
					{successMessage}
				</SweetAlert>
			)}
			{(sweetAlertWarning &&
					<SweetAlert warning showCancel
						confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
						cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="default"
						title={translations[session.language]['alerts']['warning'].title}
						onConfirm={() => {handleDeleteUser(deleteUser);setSweetAlertWarning(false)}}
						onCancel={() => setSweetAlertWarning(false)}
					>
						{translations[session.language]['backoffice']['users']['alerts']['warning'].message}
					</SweetAlert>
				)}
			{(sweetAlertError &&
					<SweetAlert danger
							confirmBtnText="OK"
							confirmBtnBsStyle="default"
							title={translations[session.language]['alerts']['error'].title}
							onConfirm={() => setSweetAlertError(false)}
					>
						{errorMessage}
					</SweetAlert>
			)}
		</div>
	)
}