import React from 'react';
import { Link } from 'react-router-dom';
import { PageSettings } from './../../config/page-settings.js';

import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from 'jwt-decode';
import md5 from 'md5';

import api from '../../services/api';
import config from '../../services/config';
import translations from '../translations.json';
import validateEmail from '../../utils/validateEmail';
import validateImage from '../../utils/validateImage';

import logout from '../../utils/logout';

class Profile extends React.Component {
  static contextType = PageSettings;

	constructor(props) {
    super(props);
    
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);

		this.state = {
      collapseInfo: false,
      expandInfo: false,
      collapseLogin: false,
      expandLogin: false,
      session: {
        language: 'pt'
      },
      sweetAlertSuccess: false,
      successMessage: '',
      sweetAlertError: false,
      errorMessage: ''
		}
	}
	componentDidMount() {
		this.context.handleSetPageContentFullHeight(true);
    this.context.handleSetPageContentFullWidth(true);
    try {
      const decode = jwt_decode(localStorage.token);
      this.setState({
        session: {
          decode
        }
      });
      document.getElementById('name').value = decode.name;
      document.getElementById('email').value = decode.email;
      document.getElementById('phone').value = decode.phone;
    } catch (err) {
      this.setState({ errorMessage: translations[this.state.session.language]['alert']['session'].error });
      this.setState({ sweetAlertError: true });
      setTimeout(() => {
        logout();
      }, 2000);
    }
	}

	componentWillUnmount() {
		this.context.handleSetPageContentFullHeight(false);
		this.context.handleSetPageContentFullWidth(false);
  }

  async handleUpdateProfile(e) {
    e.preventDefault();

    var data = {};
		var val = 0;
    var msg = "";
    
		const name = document.getElementById('name').value;
		if (name && name !== '') {
			data.name = name;
		} else {
			val++;
			msg += translations[this.state.session.language]['profile']['panel']['user-info']['validations']['name'];
    }
    
    const email = document.getElementById('email').value;
		if (email && validateEmail(email)) {
			data.email = email;
		} else {
			val++;
			msg += translations[this.state.session.language]['profile']['panel']['user-info']['validations']['email'];
    }

    const phone = document.getElementById('phone').value;
		if (phone && phone !== '') {
			data.phone = phone;
		} else {
			data.phone = '';
    }
    
    if (val !== 0) {
      this.setState({ errorMessage: msg });
      this.setState({ sweetAlertError: true });
		} else {
      try {
        const { id, language } = jwt_decode(localStorage.token);
  
        try {
          await api.put(`/profile/${id}`, data, {
            headers: {
              token: localStorage.token
            }
          })
          .then(response => {
            if(document.getElementById("file").files.length !== 0) {
              this.handleUploadImage();
            }
            this.setState({ successMessage: translations[language]['profile']['responses']['edit']['profile'][response.data.message] });
            this.setState({ sweetAlertSuccess: true });
            localStorage.setItem('token', response.data.token);
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.setState({ errorMessage: translations[this.state.session.language]['alert']['session'].error });
              setTimeout(() => {
                logout();
              }, 2000);
            } else {
              this.setState({ errorMessage: err.response.data.message });
            }
            this.setState({ sweetAlertError: true });
          })
        } catch (err) {
          alert(err.message);
        }
      } catch (err) {
        this.setState({ errorMessage: translations[this.state.session.language]['alert']['session'].error });
        this.setState({ sweetAlertError: true });
      }
    }
  }

  async handleUploadImage() {
    try {
      const { id } = jwt_decode(localStorage.token);

      const data = new FormData();
      data.append('file', document.getElementById('file').files[0]);

      try {
        await api.put(`/profile-image/${id}`, data, {
          headers: {
            token: localStorage.token
          }
        })
        .then(response => {
          localStorage.setItem('token', response.data.token);
          document.getElementById('file').value = '';
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.setState({ errorMessage: translations[this.state.session.language]['alert']['session'].error });
            setTimeout(() => {
              logout();
            }, 2000);
          } else {
            this.setState({ errorMessage: err.response.data.message });
          }
          this.setState({ sweetAlertError: true });
        })
      } catch (err) {
        alert(err.message);
      }
    } catch (err) {
      this.setState({ errorMessage: translations[this.state.session.language]['alert']['session'].error });
      this.setState({ sweetAlertError: true });
      setTimeout(() => {
        logout();
      }, 2000);
    }
  }

  async handleUpdatePassword(e) {
    e.preventDefault();

    var data = {};
		var val = 0;
    var msg = "";
    
		const oldPassword = document.getElementById('oldPassword').value;
		if (oldPassword && oldPassword !== '') {
			data.oldPassword = md5(oldPassword);
		} else {
			val++;
			msg += translations[this.state.session.language]['profile']['panel']['login']['validations']['password'];
    }
    
    const newPassword = document.getElementById('newPassword').value;
		if (newPassword && newPassword !== '') {
      var regExp = /[a-zA-Z]/g;
      var regExpN = /[0-9]/g;
      var testString = newPassword;
      
      if(regExp.test(testString) !== false && regExpN.test(testString) !== false && newPassword.length >= 8){
        if(md5(oldPassword) === md5(newPassword)){
          val++;
          msg += "A password introduzida não pode ser igual à password atual.";
        }else{
          data.newPassword = md5(newPassword);
        }
      }else{
        val++;
        msg += "A password introduzida preenche os requisitos obrigatórios.";
      }
		} else {
			val++;
			msg += translations[this.state.session.language]['profile']['panel']['login']['validations']['new-password'];
    }

    const newPasswordConfirmation = document.getElementById('newPasswordConfirmation').value;
		if (newPasswordConfirmation && newPasswordConfirmation !== '') {
			data.newPasswordConfirmation = md5(newPasswordConfirmation);
		} else {
			val++;
			msg += translations[this.state.session.language]['profile']['panel']['login']['validations']['new-password-confirmation'];
    }
    
    if (val !== 0) {
      this.setState({ errorMessage: msg });
      this.setState({ sweetAlertError: true });
		} else {
      if (newPassword === newPasswordConfirmation) {
        try {
          const { id, language } = jwt_decode(localStorage.token);
  
          try {
            await api.put(`/profile-login/${id}`, data)
            .then(response => {
              if(response === "success"){
                this.setState({ successMessage: translations[language]['profile']['responses']['edit']['password'][response.data] });
                this.setState({ sweetAlertSuccess: true });
                document.getElementById('oldPassword').value = '';
                document.getElementById('newPassword').value = '';
                document.getElementById('newPasswordConfirmation').value = '';
              }else{
                this.setState({ successMessage: "Para garantir a implementação segura desta primeira alteração, a sua sessão será terminada." });
                this.setState({ sweetAlertSuccess: true });
                document.getElementById('oldPassword').value = '';
                document.getElementById('newPassword').value = '';
                document.getElementById('newPasswordConfirmation').value = '';
                setTimeout(function(){logout()},3000);
              }
              
            })
            .catch(err => {
              this.setState({ errorMessage: translations[language]['profile']['responses']['edit']['password'][err.response.data.message] });
              this.setState({ sweetAlertError: true });
            })
          } catch (err) {
            alert(err.message);
          }
        } catch (err) {
          this.setState({ errorMessage: translations[this.state.session.language]['alert']['session'].error });
          this.setState({ sweetAlertError: true });
          setTimeout(() => {
            logout();
          }, 2000);
        }
      } else {
        this.setState({ errorMessage: translations[this.state.session.language]['profile']['panel']['login']['validations']['password-match'] });
        this.setState({ sweetAlertError: true });
      }
    }
  }
	
	render() {
    try {
      var { name, photo, description, language } = jwt_decode(localStorage.token);
    } catch (err) {
      this.setState({ errorMessage: translations[this.state.session.language]['alert']['session'].error });
      this.setState({ sweetAlertError: true });
      setTimeout(() => {
        logout();
      }, 2000);
    }
		return (
			<div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover" style={{backgroundImage: 'none'}}></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img src={config.base_url+photo} alt="Profile" style={{width: '100%', height: '100%', maxHeight: '100%'}} />
            </div>
            <div className="profile-header-info">
              <h4 className="m-t-10 m-b-5">{name}</h4>
              <p className="m-b-10">{description}</p>
              <Link to="#">&nbsp;</Link>
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item"><Link to="#" className='nav-link active'>{translations[language]['profile']['header']['title']}</Link></li>
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="row">
          <div className="col-md-6">
            <div className={'panel panel-inverse ' + (this.state.expandInfo ? 'panel-expand ' : ' ')}>
              <div className="panel-heading">
                <h4 className="panel-title">{translations[language]['profile']['panel']['user-info']['title']}</h4>
                  <div className="panel-heading-btn">
                    <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => this.setState({expandInfo: !this.state.expandInfo})}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
                    <button className="btn btn-xs btn-icon btn-circle btn-danger" onClick={() => this.setState({collapseInfo: !this.state.collapseInfo})}><i className="fa fa-minus"></i></button>&nbsp;&nbsp;
                  </div>
              </div>
              <div className={'panel-body ' + (this.state.collapseInfo ? 'd-none ' : ' ')}>
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={this.handleUpdateProfile}>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-2">{translations[language]['profile']['panel']['user-info']['body']['name']['title']}</label>
                        <div className="col-md-10">
                          <input 
                            type="text" 
                            className="form-control" 
                            id="name"
                            placeholder={translations[language]['profile']['panel']['user-info']['body']['name']['placeholder']}
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-2">{translations[language]['profile']['panel']['user-info']['body']['email']['title']}</label>
                        <div className="col-md-10">
                          <input 
                            type="email" 
                            className="form-control" 
                            id="email"
                            placeholder={translations[language]['profile']['panel']['user-info']['body']['email']['placeholder']} 
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-2">{translations[language]['profile']['panel']['user-info']['body']['phone']['title']}</label>
                        <div className="col-md-10">
                          <input 
                            type="text" 
                            className="form-control" 
                            id="phone"
                            placeholder={translations[language]['profile']['panel']['user-info']['body']['phone']['placeholder']}
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-2">{translations[language]['profile']['panel']['user-info']['body']['photo']['title']}</label>
                        <div className="col-md-10">
                          <input 
                            type="file" 
                            className="form-control"
                            id="file"
                            onChange={
                              (e) => {
                                const image = e.target.files[0];
                                if (validateImage(image)) {
                                  if (image.size > (3 * 1024 * 1024)) {
                                    this.setState({ errorMessage: translations[language]['profile']['panel']['user-info']['body']['photo']['size'] });
                                    this.setState({ sweetAlertError: true });
                                  }
                                } else {
                                  document.getElementById('file').value = null;
                                  this.setState({ errorMessage: translations[language]['utils']['validateImage']['extension'] });
                                  this.setState({ sweetAlertError: true });
                                }
                              }
                            }
                          />
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary pull-right">{translations[language]['profile']['panel']['user-info']['body']['buttons']['save']}</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={'panel panel-inverse ' + (this.state.expandLogin ? 'panel-expand ' : ' ')}>
              <div className="panel-heading">
                <h4 className="panel-title">{translations[language]['profile']['panel']['login']['title']}</h4>
                  <div className="panel-heading-btn">
                    <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => this.setState({expandLogin: !this.state.expandLogin})}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
                    <button className="btn btn-xs btn-icon btn-circle btn-danger" onClick={() => this.setState({collapseLogin: !this.state.collapseLogin})}><i className="fa fa-minus"></i></button>&nbsp;&nbsp;
                  </div>
              </div>
              <div className={'panel-body ' + (this.state.collapseLogin ? 'd-none ' : ' ')}>
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={this.handleUpdatePassword}>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">{translations[language]['profile']['panel']['login']['body']['password']['title']}</label>
                        <div className="col-md-9">
                          <input 
                            type="password" 
                            className="form-control" 
                            id="oldPassword"
                            placeholder={translations[language]['profile']['panel']['login']['body']['password']['placeholder']}
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">{translations[language]['profile']['panel']['login']['body']['new-password']['title']}</label>
                        <div className="col-md-9">
                          <input 
                            type="password" 
                            className="form-control" 
                            id="newPassword"
                            placeholder={translations[language]['profile']['panel']['login']['body']['new-password']['placeholder']} 
                          />
                          <small className="f-s-12 text-grey-darker">Password com 8 ou mais caracteres, alfanumérica.</small>
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">{translations[language]['profile']['panel']['login']['body']['new-password-confirmation']['title']}</label>
                        <div className="col-md-9">
                          <input 
                            type="password" 
                            className="form-control" 
                            id="newPasswordConfirmation"
                            placeholder={translations[language]['profile']['panel']['login']['body']['new-password-confirmation']['placeholder']} 
                          />
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary pull-right">{translations[language]['profile']['panel']['login']['body']['buttons']['save']}</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(this.state.sweetAlertSuccess &&
				<SweetAlert success
					confirmBtnText="OK"
					confirmBtnBsStyle="success"
					title={translations[language]['alerts']['success'].title}
					onConfirm={() => this.setState({ sweetAlertSuccess: false })}
				>
					{this.state.successMessage}
				</SweetAlert>
			)}
      {(this.state.sweetAlertError &&
        <SweetAlert danger
            confirmBtnText="OK"
            confirmBtnBsStyle="default"
            title={translations[language]['alerts']['error'].title}
            onConfirm={() => this.setState({ sweetAlertError: false })}
        >
          {this.state.errorMessage}
        </SweetAlert>
			)}
    </div>
		)
	}
}

export default Profile;